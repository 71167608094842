.project-metrics
	position relative // for mobile
	&.content__body__container
		padding-top 0
	&__navtab-container
		background-color $light
		z-index 50
	&__layout
		position relative
		z-index 1
		margin-top 2rem
		padding 0 $contentPadding
		height 100%
		width 100%
		&__header
			mbi(3)
			display flex
			align-items center
		.title
			margin-bottom 0
		.subtitle
			padding 0 3rem
	&__header
		position relative
		z-index 60
		display flex
		align-items center
		justify-content space-between
		flex-wrap wrap
		padding 0 2.6rem
		&__right
		&__left
			mbi(2)
			display flex
			align-items center
			flex-wrap wrap
			margin 1rem 0
		&__queries
			mbi()
			display flex
			.subtitle
				font-size $title5
				margin-bottom 0
				white-space nowrap
			&__value
				br()
				display flex
				justify-content center
				align-items center
				height $buttonHeightSmall
				color $secondary
				background-color $light
				padding 0 1rem
				font-weight 700
				min-width 5rem
				text-align center
				border .1rem solid $secondary
				.icon
					stroke $light
		.btn--group
			&.active
				background-color $secondary
				border-color  $secondary
				color $light
				font-weight 600
				&:hover
					background-color $secondary
					border-color  $secondary
					color $light
					text-shadow none
			&:hover
				background-color $secondary
				border-color  $secondary
				color $light
				textShadow()
	&__legend-container
		display flex
		align-items center
		justify-content center
		margin-bottom 3.6rem
	&__legend
		fullw()
		padding 2rem
		max-width 80rem
		li
			&:nth-child(odd)
				.dashboard__products__list__item
					padding-right 2rem
			&:nth-child(even)
				.dashboard__products__list__item
					padding-left 2rem
		&__item
			fullw()
			br()
			trans()
			mbi()
			display flex
			align-items center
			margin 0 0 1.4rem 0
			cursor pointer
			font-size $title5
			background-color $primary6
			padding 1rem 1rem 1rem 2rem
			&:hover
				background-color $primary15
			&.inactive
				opacity .4
		&__label
			fullw()
			mbi(.8)
			display flex
			flex-wrap nowrap
			.inactive &
				text-decoration line-through
			&__name
				ellipsis()
				mbi(.8)
				width 100%
				display flex
				align-items center
		&__queries
			fullw()
			text-align right
			white-space nowrap
			font-weight 700
			&-details
				font-size $fontSizeSmall
				color $secondary-medium
				white-space nowrap
				text-align right
			&-container
				display flex
				flex-direction column
				align-items flex-start
				justify-content center
				flex-wrap wrap
		&__cost
			br()
			mbi(.2)
			display flex
			align-items center
			text-align right
			height 3.2rem
			padding 0 1rem
			background-color $light
			color $primary60
			border .1rem solid $primary20
			&__currency
				color $primary40
				position relative
				top -.6rem
				right -.5rem
				font-size $fontSizeMini
				font-weight 700

		&__link
			.btn
			.icon
				margin 0
				sq(1.8)
	&__donut
		flexAxis()
		max-width 23rem
		flex-shrink 0
		flex-grow 1
		margin-left 5rem
		canvas
			max-width 23rem
		&.projects
			br()
			mbib(3)
			max-width 30rem
			margin-left 3rem
			flex-direction column
			padding 2rem 3rem
			canvas
				max-width 30rem
	&__chart
		margin 1.6rem 0 3.6rem 0
		canvas
			max-height $canvasMaxHeight - 12
	&__graphs-line
		canvas
			margin-bottom 4rem
	.no-data
	.loading
		text-align center
		margin-top 4rem
@media screen and (max-width 1200px)
	.project-metrics
		&__legend__queries-details
			white-space normal
@media screen and (max-width 1090px)
	.project-metrics
		&__legend
			padding 0
			margin-bottom 3rem
			&-container
				max-width 100%
		&__chart
			padding 0
@media screen and (max-width 960px)
	.project-metrics
		&__legend
			&-container
				flex-direction column
@media screen and (max-width 880px)
	.project-metrics
		&__header
			margin-left 1rem
			margin-right 1rem
			padding .4rem .6rem
@media screen and (max-width 840px)
	.project-metrics
		&__legend
			&-container
				&.overview
					.project-metrics__legend__label
						flex-wrap wrap
			&__label
				&__queries
				&__queries-details
					text-align left
		.subtitle
			padding 0
		&__layout
			&__header
				margin-left 0
		&__header
			margin 0 1rem
			padding .4rem 1rem
@media screen and (max-width 720px)
	.project-metrics
		&__legend-container
			flex-direction column
		&__legend
			margin-right 0
			width 100%
		&__layout
			padding 0 1.6rem
			display flex
			flex-direction column
			.subtitle
				font-size $title5
			&__header
				.title
					font-size $title4
@media screen and (max-width 420px)
	.project-metrics
		&__legend
			&__item
				align-items flex-start
				.badge
					margin-top .7rem
			&__queries
			&__queries-details
				text-align left
			&__cost
				margin-left 0
			&__label
				flex-direction column
				align-items flex-start
				mbi(0)
				mbib(.6)
				&__name
					display inherit
					align-items inherit
					margin-right
					span
						ellipsis()
		&__donut
			margin-top 2rem
			padding 2rem
			canvas
				max-width 16rem
				max-height 16rem
		&__header
			margin 0
			&__right
			&__left
				mbi(1)
			&__left
				width 100%
				margin-bottom 0
			&__right
				flex-direction column
				width 100%
				align-items flex-start
				.metric-picker
					margin-right 0 !important
					width 100% !important
					margin-bottom 1rem
			&__queries
				.subtitle
					font-size 1.3rem
list()
	flexMiddle()
	br()
	transition all .3s ease
	transition-property box-shadow, border
	width 100%
	min-height $listHeight
	border .1rem solid $secondary-light
	margin-bottom 1rem
	padding 0 $listPadding
.list
	&-filters
		display flex
		align-items center
		gap 2rem
	.ReactVirtualized__Grid__innerScrollContainer
		overflow visible !important
	height 100%
	width 100%
	position relative
	.inactive-label
		color $secondary
		margin-left 1rem
		display inline-block
		font-style italic
		font-weight 400
	&__item
		position relative
		width 100%
		min-width 0
		&--count
			font-weight bold
			padding-bottom 1.5rem
			&__number
				color $primary
		&--highlighted-item
			padding-bottom .6rem
			margin-bottom 1.6rem
			border-bottom .1rem solid $borderColor
			&:before
				color $dark40
				font-size $fontSizeSmall
				display block
				margin-bottom 1rem
				content attr(data-above)
	&__check
		&__selected
			background-color $listItemCheck
		&__icon
			stroke $light
		sq(2.6)
		br(50)
		background-color: rgba($secondary, 5%)
		margin-right: 2rem
		display: flex
		justify-content: center
		align-items: center
	&__header
		fullw()
		flexMiddle()
		height 3.3rem
		background $secondary-light40
		padding 0 5rem 0 4.6rem
		flex-shrink 0
		font-size $fontSizeSmall
		border-bottom .1rem solid $borderColor
	&__filter
		display flex
		align-items center
		padding 0 $contentPadding
		min-height 5.2rem
		border-bottom .1rem solid $borderColor
		flex-shrink 1
		background-color $secondary-light10
		.input
			width auto
			flex-grow 1
		&__selected
			display flex
			align-items center
			flex-shrink 1
			flex-wrap wrap
			> *
				margin-right .6rem
				&:last-child
					margin-right 2rem
		&--last-activities
		&--orgs
		&--users
			.select
				width auto
				padding .2rem 1rem
				background $primary10
				&__line
					display flex
					align-items center
				&__label
					margin 0 1rem 0 0
				&__control
					border 0 !important
					background transparent !important
				&__indicator-separator
					display none !important
		&--users
		&--orgs
			.select
				br()
				&__indicators
					display none !important
				&__value-container
					padding 0 !important
				&__control
					min-width 22rem
					min-height 3.2rem !important
		&--last-activities
			flex-shrink 0
			padding 0
			border-bottom 0
			background transparent
			.select
				border-radius 0
				background transparent
				height 100%
				flex-shrink 1
				border-bottom .1rem solid $borderColor
				height $listHeight
				background-color $secondary-light10
				&__dropdown-indicator
					cursor pointer
				&__line
				&__item
				&__control
					height 100%
					width 100%
				&__control
					min-width 12rem
				&__value-container
					flex-wrap nowrap
				&:not(.action-type)
					border-right .1rem solid $borderColor
					.select__clear-indicator
						display none
				&__indicators
					svg
						sq(1.6)
				&__multi-value
					background-color $secondary !important
					min-height $buttonHeightSmall !important
					border-radius .6rem !important
					&__label
						color $light !important
					&__remove
						svg
							stroke $light !important
				&.object-type
					flex-grow 1
				&.action-type
					.select__value-container
						height $buttonHeightSmall
						&--has-value
							border-radius .6rem 0 0 .6rem
							background-color $secondary
							height $buttonHeightSmall
							display flex
							align-items center
							padding 0 .3rem !important
							min-width unset
							flex-grow 0
							overflow unset
							> :not(.select__single-value)
								position absolute
					.select
						&__control
							justify-content flex-start
							flex-wrap nowrap
						&__dropdown-indicator
							margin-left auto
						&__indicators
							flex-grow 1
							justify-content space-between
						&__single-value
							position relative
							top unset
							left unset
							max-width unset
							color $light !important
							font-size  1.2rem !important
							transform unset
						&__clear-indicator
							padding 0 .4rem 0 0 !important
							height $buttonHeightSmall
							background-color $secondary
							border-radius 0 .6rem .6rem 0
							display flex
							align-items center
							cursor pointer
							svg
								stroke $light !important
								sq(1.4)
			.date-range-filter
				height $listHeight
				padding-left 2.8rem
				flex-grow 1
				border-bottom .1rem solid $borderColor
				background-color $secondary-light10
				.popover__child
					flex 1
				&__label
					flex 1
					.icon
						margin-left auto
				.label
					flex-shrink 1
					margin-right 1.6rem
					&__name
						ellipsis()
						font-size $fontSizeSmall
		&--last
			.select
				display flex
				align-items center
				height 100%
				border-right .1rem solid $borderColor
				border-radius 0
				background transparent
				&__indicators
					display flex !important
				&__indicator-separator
					display none !important
				&__multi-value
					background-color $light !important
					border .1rem solid $borderColor
					min-height $buttonHeightSmall !important
					border-radius .6rem !important
					&__label
						color $secondary !important
						font-size $buttonFontSizeSmall !important
					&__remove 
						svg
							stroke $borderColor !important
	&__row
		fullwh()
		flexMiddle()
		list()
		.list--small &
			min-height $listHeight - 1
			margin-bottom .4rem
			padding 0 1.2rem
		.list--clickable &
			cursor pointer
			&:hover
				background-color $primary6
				box-shadow 0 0 .6rem $secondary20
				border .1rem solid $secondary30
		.list__item--highlighted-item &
			&:hover
				background-color #d2e2ff
				border .1rem solid $dark10
		.list--actions-on-hover &
			&:hover
				.list__col--action
					.btn
						visibility visible
					.dropdown__menu
						visibility visible
		.list__item--highlighted-item &
			background-color #e5eeff
		.list__item--previous-item &
			background-color #fffae3 !important
			border .1rem solid $dark10 !important
		&:focus-visible
			.simplecard
				box-shadow inset 0 0 0 .1rem $focus
		&.row-inactive
			color $secondary40
			background-color $dark3
			.icon:not(.simplecard__favorite)
				stroke $secondary40
		&--no-border
			&.list__row
				border 0
		&--height-auto
			&.list__row
				min-height unset
		&--height-small
			&.list__row
				min-height 3rem
	&__loader
		flexMiddle()
		width 100%
		justify-content center
	&__scroll-to-top
		position absolute
		right 3.1rem
		top 3rem
		z-index 9
		.content__sidebar &
			top auto
			bottom 1.6rem
			right 1.6rem
	&__scroll-to-top-btn
		&.btn
			background-color $light80
			border-color $secondary20
			box-shadow 0 0 1rem $secondary20
			.icon
				stroke $secondary40
			&:hover
				background-color $light !important
				border-color $secondary90 !important
				.icon
					stroke $secondary90 !important
	&__cols
		flexMiddle()
		mbi(1)
		width 100%
		flex-grow 1
		.admin &
			.list__col:not(.list__col--fill)
				text-align center
				.btn
					margin auto !important
	&__col
		width 100%
		min-width 3rem
		max-width 15rem
		flex-shrink 1
		&:not(.list__col--action) // if not dropdown actions are hidden by the overflow
			ellipsis()
		&:last-child
			margin-left auto
		&--fill
			min-width 24rem
			max-width none
			font-weight 700
			.list__header &
				font-weight 400
			.iconed-col &
			.list__item--highlighted-item &
				display flex
				align-items center
				mbi(1)
				.icon
					flex-shrink 0
				span
					white-space nowrap
					overflow hidden
					text-overflow ellipsis
		&.expired
			color $error
			font-weight 700
		&.overflow
			overflow visible
		&--action
			flexAxis()
			width 3rem
			.dropdown__menu
			.btn
				trans()
			.list--actions-on-hover &
				.dropdown__menu
					visibility hidden
				.btn
					visibility hidden
	&--link
		display flex
		flex-direction column
		align-items flex-start
		ul
			width 100%
		.btn
			margin-top auto
		.list__item
			text-decoration none
			flexMiddle()
			br()
			trans()
			min-height $buttonHeight
			margin-bottom .1rem
			padding 0 $listPadding
			border .1rem solid transparent
			color $secondary
			&:hover
			&.active
				background-color $primary6
				border .1rem solid $secondary20
			&.active
				font-weight 700
	&--bullet
		li
			list-style inside disc
	&--card
		.list__item
			flexCol()
			br()
			trans()
			background-color #fff
			border .1rem solid $secondary20
			cursor pointer
			margin 0 auto 1rem auto
			padding 1.6rem
			width 100%
			height 8rem
			font-weight 700
			&.active
				background-color $primary6
				border .1rem solid $secondary60
				font-weight 700
				cursor default
			&:hover:not(.active):not(.disabled)
				box-shadow 0 0 .6rem $secondary20
				border .1rem solid $secondary60
			&.disabled
				background $secondary15
				cursor default
				opacity .6
@media screen and (max-width 1090px)
	.list__filter--last-activities
		flex-direction column
		.select
			width 100%
		.date-range-filter
			width 100%

@media screen and (max-width 720px)
	.list
		padding 1rem !important
		&__col
			&--fill
				min-width 12rem
			&--action
				display none
		&__row
			padding 0 1rem
		&--link
			.list__item
				height 5rem
				background-color #fff
				border .1rem solid $borderColor
				margin-bottom 1rem
				font-weight 700
				&.active
					background-color #fff
					border .1rem solid $borderColor
					cursor pointer
				&:hover
					background-color $primary6
					border .1rem solid $secondary20
		&__scroll-to-top
			right 2.1rem
			top 2rem
.Toastify
	&__toast-container
		z-index 1070
		-webkit-transform translate3d(0, 0, 999.9rem)
		position fixed
		padding .4rem
		max-width 48rem
		box-sizing border-box
		color $light
		&--top-left
			top 1em
			left 1em
		&--top-center
			top 1em
			left 50%
			transform translateX(-50%)
		&--top-right
			top 1em
			right 1em
		&--bottom-left
			bottom 1em
			left 1em
		&--bottom-center
			bottom 1em
			left 50%
			transform translateX(-50%)
		&--bottom-right
			bottom 1em
			right 1em

@media only screen and (max-width : 480px)
	.Toastify
		&__toast-container
			width 100vw
			padding 0
			left 0
			margin 0
			&--top-left
			&--top-center
			&--top-right
				top 0
				transform translateX(0)
			&--bottom-left
			&--bottom-center
			&--bottom-right
				bottom 0
				transform translateX(0)
			&--rtl
				right 0
				left initial
.Toastify
	&__toast
		position relative
		min-height 6.4rem
		box-sizing border-box
		margin-bottom 1rem
		padding .8rem
		border-radius .4rem
		box-shadow 0 .1rem 1rem 0 $dark10, 0 .2rem 1.5rem 0 $dark5
		display flex
		justify-content space-between
		max-height 80rem
		overflow hidden
		cursor pointer
		direction ltr
		&--rtl
			direction rtl
		&--dark
			background #121212
			color $light
		&--default
			background $light
			color #aaa
		&--info
			background $info
		&--success
			background $success
		&--warning
			background $warning
		&--error
			background $error
		&-body
			margin auto 0
			flex 1 1 auto
			padding .8rem 1.2rem
			font-size $title4
			line-height $title4 + 1
	&--animate
		animation-fill-mode both
		animation-duration 0.7s

@media only screen and (max-width : 480px)
	.Toastify__toast
		margin-bottom 0
		border-radius 0

.Toastify
	&__close-button
		color $light
		background transparent
		outline none
		border none
		padding 0
		cursor pointer
		opacity 0.7
		transition 0.3s ease

		align-self flex-start
		&--default
			color #000
			opacity 0.3
		> svg
			fill currentColor
			height 1.6rem
			width 1.4rem
		&:hover
		&:focus
			opacity 1

@keyframes Toastify__trackProgress
	0%
		transform scaleX(1)
	100%
		transform scaleX(0)
.Toastify
	&__progress-bar
		position absolute
		bottom 0
		left 0
		width 100%
		height .5rem
		z-index 9999
		opacity 0.7
		background-color rgba(255, 255, 255, 0.7)
		transform-origin left
		&--animated
			animation Toastify__trackProgress linear 1 forwards
		&--controlled
			transition transform 0.2s
		&--rtl
			right 0
			left initial
			transform-origin right
		&--default
			background linear-gradient(to right, #4cd964, #5ac8fa, #007aff, #34aadc, #5856d6, #ff2d55)
		&--dark
			background #bb86fc
@keyframes Toastify__bounceInRight
	from, 60%, 75%, 90%, to
		animation-timing-function cubic-bezier(0.215, 0.61, 0.355, 1)
	from
		opacity 0
		transform translate3d(300rem, 0, 0)
	60%
		opacity 1
		transform translate3d(-2.5rem, 0, 0)
	75%
		transform translate3d(1rem, 0, 0)
	90%
		transform translate3d(-.5rem, 0, 0)
	to
		transform none
@keyframes Toastify__bounceOutRight
	20%
		opacity 1
		transform translate3d(-2rem, 0, 0)
	to
		opacity 0
		transform translate3d(200rem, 0, 0)
@keyframes Toastify__bounceInLeft
	from, 60%, 75%, 90%, to
		animation-timing-function cubic-bezier(0.215, 0.61, 0.355, 1)
	0%
		opacity 0
		transform translate3d(-300rem, 0, 0)
	60%
		opacity 1
		transform translate3d(2.5rem, 0, 0)
	75%
		transform translate3d(-1rem, 0, 0)
	90%
		transform translate3d(.5rem, 0, 0)
	to
		transform none
@keyframes Toastify__bounceOutLeft
	20%
		opacity 1
		transform translate3d(2rem, 0, 0)
	to
		opacity 0
		transform translate3d(-200rem, 0, 0)
@keyframes Toastify__bounceInUp
	from, 60%, 75%, 90%, to
		animation-timing-function cubic-bezier(0.215, 0.61, 0.355, 1)
	from
		opacity 0
		transform translate3d(0, 300rem, 0)
	60%
		opacity 1
		transform translate3d(0, -2rem, 0)
	75%
		transform translate3d(0, 1rem, 0)
	90%
		transform translate3d(0, -.5rem, 0)
	to
		transform translate3d(0, 0, 0)
@keyframes Toastify__bounceOutUp
	20%
		transform translate3d(0, -1rem, 0)
	40%, 45%
		opacity 1
		transform translate3d(0, 2rem, 0)
	to
		opacity 0
		transform translate3d(0, -200rem, 0)
@keyframes Toastify__bounceInDown
	from, 60%, 75%, 90%, to
		animation-timing-function cubic-bezier(0.215, 0.61, 0.355, 1)
	0%
		opacity 0
		transform translate3d(0, -300rem, 0)
	60%
		opacity 1
		transform translate3d(0, 2.5rem, 0)
	75%
		transform translate3d(0, -1rem, 0)
	90%
		transform translate3d(0, .5rem, 0)
	to
		transform none
@keyframes Toastify__bounceOutDown
	20%
		transform translate3d(0, 1rem, 0)
	40%, 45%
		opacity 1
		transform translate3d(0, -2rem, 0)
	to
		opacity 0
		transform translate3d(0, 200rem, 0)
.Toastify
	&__bounce-enter
		&--top-left
		&--bottom-left
			animation-name Toastify__bounceInLeft
		&--top-right
		&--bottom-right
			animation-name Toastify__bounceInRight
		&--top-center
			animation-name Toastify__bounceInDown
		&--bottom-center
			animation-name Toastify__bounceInUp
	&__bounce-exit
		&--top-left
		&--bottom-left
			animation-name Toastify__bounceOutLeft
		&--top-right
		&--bottom-right
			animation-name Toastify__bounceOutRight
		&--top-center
			animation-name Toastify__bounceOutUp
		&--bottom-center
			animation-name Toastify__bounceOutDown

@keyframes Toastify__zoomIn
	from
		opacity 0
		transform scale3d(0.3, 0.3, 0.3)
	50%
		opacity 1
@keyframes Toastify__zoomOut
	from
		opacity 1
	50%
		opacity 0
		transform scale3d(0.3, 0.3, 0.3)
	to
		opacity 0
.Toastify__zoom-enter
	animation-name Toastify__zoomIn

.Toastify__zoom-exit
	animation-name Toastify__zoomOut

@keyframes Toastify__flipIn
	from
		transform perspective(40rem) rotate3d(1, 0, 0, 90deg)
		animation-timing-function ease-in
		opacity 0
	40%
		transform perspective(40rem) rotate3d(1, 0, 0, -20deg)
		animation-timing-function ease-in
	60%
		transform perspective(40rem) rotate3d(1, 0, 0, 10deg)
		opacity 1
	80%
		transform perspective(40rem) rotate3d(1, 0, 0, -5deg)
	to
		transform perspective(40rem)
@keyframes Toastify__flipOut
	from
		transform perspective(40rem)
	30%
		transform perspective(40rem) rotate3d(1, 0, 0, -20deg)
		opacity 1
	to
		transform perspective(40rem) rotate3d(1, 0, 0, 90deg)
		opacity 0
.Toastify
	&__flip-enter
		animation-name Toastify__flipIn
	&__flip-exit
		animation-name Toastify__flipOut

@keyframes Toastify__slideInRight
	from
		transform translate3d(110%, 0, 0)
		visibility visible
	to
		transform translate3d(0, 0, 0)
@keyframes Toastify__slideInLeft
	from
		transform translate3d(-110%, 0, 0)
		visibility visible
	to
		transform translate3d(0, 0, 0)
@keyframes Toastify__slideInUp
	from
		transform translate3d(0, 110%, 0)
		visibility visible
	to
		transform translate3d(0, 0, 0)
@keyframes Toastify__slideInDown
	from
		transform translate3d(0, -110%, 0)
		visibility visible
	to
		transform translate3d(0, 0, 0)
@keyframes Toastify__slideOutRight
	from
		transform translate3d(0, 0, 0)
	to
		visibility hidden
		transform translate3d(110%, 0, 0)
@keyframes Toastify__slideOutLeft
	from
		transform translate3d(0, 0, 0)
	to
		visibility hidden
		transform translate3d(-110%, 0, 0)
@keyframes Toastify__slideOutDown
	from
		transform translate3d(0, 0, 0)
	to
		visibility hidden
		transform translate3d(0, 50rem, 0)
@keyframes Toastify__slideOutUp
	from
		transform translate3d(0, 0, 0)
	to
		visibility hidden
		transform translate3d(0, -50rem, 0)
.Toastify
	&__slide-enter
		&--top-left
		&--bottom-left
			animation-name Toastify__slideInLeft
		&--top-right
		&--bottom-right
			animation-name Toastify__slideInRight
		&--top-center
			animation-name Toastify__slideInDown
		&--bottom-center
			animation-name Toastify__slideInUp
	&__slide-exit
		&--top-left
		&--bottom-left
			animation-name Toastify__slideOutLeft
		&--top-right
		&--bottom-right
			animation-name Toastify__slideOutRight
		&--top-center
			animation-name Toastify__slideOutUp
		&--bottom-center
			animation-name Toastify__slideOutDown
.assets-upload
	&__file
		&-container
			width 100%
			display flex
			flex-direction column
		&-list
			mbib(.6)
			.listify
				background-color $success50
				border-color $success

			.listify_error
				background-color $error30
				border-color $error
.metric-picker
	mbi(1)
	display flex
	align-items center
	flex-shrink 0
	margin-left auto
	&__wrapper
		mbi(1)
		display flex
		align-items center
		min-width 0
	.select__control
		min-width 14rem
	.label
		background-color $light
		color $secondary
		text-shadow none
		height $buttonHeightSmall
		margin-top 0
		margin-bottom 0
		padding 0 1.6rem
		border .1rem solid $inputBorderColor
		.btn
			margin-left 1rem
			.icon
				stroke $secondary
			&:hover
				.icon
					stroke $success2
.date-range
	width 100%
	&__footer
		mbi()
		display flex
		align-items center
		justify-content flex-end
		margin-top 1rem
	.rdrMonths
		justify-content center
		flex-grow 1
		height auto
	.rdrStaticRangeLabel
		font-size $fontSize
	.rdrMonth
		max-width 24rem
		min-width 20rem
		width auto
		flex-grow 1
		padding 0 2rem
	.rdrCalendarWrapper
		width 100%
	.rdrDay
		height 3rem
	.rdrDayNumber
		font-weight 700
	.rdrWeekDay
		font-weight 400
	.rdrDayToday
		&:not(.rdrDayPassive)
			.rdrEndEdge ~ .rdrDayNumber
				span
					color $light !important
					position relative
					z-index 1
					&:after
						br(50)
						sq(1.8)
						z-index -1
						top .9rem
						left .4rem
						position absolute
						background-color rgba(#f50057, 80%)
	.rdrDayToday
		.rdrDayNumber
			span
				color $light !important
				position relative
				z-index 1
				&:after
					br(50)
					sq(1.8)
					z-index -1
					top .9rem
					left .4rem
					position absolute
					background-color rgba(#f50057, 80%)
	
	.flash-container
		display flex
		justify-content flex-end
		align-items center
		height 8rem
	.flash
		display block
		margin 0 1rem
		max-width 69rem
		width 100%
	.rdrDefinedRangesWrapper
		border 0
	.rdrStaticRange
		background transparent
		&:hover
		&:focus
			.rdrStaticRangeLabel
				background $primary10
	.rdrStaticRanges
		max-height 31.4rem
		padding-bottom 0
		box-shadow inset 0 -5.9rem 2rem -6rem $dark30 !important
	.rdrMonthAndYearPickers
	.rdrInputRanges
		display none
	.rdrDateDisplayWrapper
		background transparent
	.rdrDateDisplayItem
		box-shadow 0 .1rem .3rem $dark30 !important
		min-width 8rem
		width 100%
		position relative
		input
			text-align left
			padding 0 1.2rem
			-webkit-user-select text
		& + .rdrDateDisplayItem
				margin-left 4.4rem
				&:after
					content '\2192'
					sq(3.2)
					display flex
					align-items center
					justify-content center
					z-index 99999
					position absolute
					top 0
					left -3.8rem
					font-size  2rem
					color $textColor
	.rdrDateDisplayItem
		input
			color $textColor
	.rdrDateDisplay
		margin-bottom 2rem
		justify-content flex-start
		border-bottom .1rem solid $borderColor
		padding-bottom 1.6rem
	.rdrMonthName
		padding 0
		text-align center
		color $textColor
		font-size $fontSize
	.rdrCalendarWrapper
		position relative
	.rdrMonthAndYearWrapper
		position absolute
		top 5.6rem
		left 0
		width 100%
	.rdrMonthsHorizontal
		padding 0 4rem 0 4rem
	.rdrNextPrevButton.rdrNextButton
	.rdrNextPrevButton.rdrPprevButton
		sq(3.2)
		trans()
		display flex
		justify-content center
		align-items center
		background $primary10
		i
			trans()
		&:hover
			background-color $primary
	.rdrPprevButton
		i
			margin 0
			border-width 5px 9px 5px 5px
		&:hover
			i
				border-color transparent $light transparent transparent
	.rdrNextButton
		i
			margin 0
			border-width 5px 5px 5px 9px
		&:hover
			i
				border-color transparent transparent transparent $light
	.rdrDayStartPreview
	.rdrDayEndPreview
	.rdrDayInPreview
		border-color rgba($success2, 70%)
		background-color rgba($success2, 10%)
@media screen and (max-width 960px)
	.date-range
		.rdrMonthsHorizontal
			flex-wrap wrap
@media screen and (max-width 768px)
	.metric-picker__popover
		height 100%
		top 0 !important
		left 0 !important
		.date-range
			height 100%
			display flex
			flex-direction column
			.rdrDateRangePickerWrapper
				flex-direction column
				overflow-y auto
				height 100%
				.rdrDefinedRangesWrapper
					width 100%
					height 100%
			.rdrCalendarWrapper
				height 100%
			.rdrDefinedRangesWrapper
				display none

.privileges
	&__table
		padding 3rem 1rem
	&__row
		mbib()
		display flex
		align-items flex-start
		margin-bottom 3rem
		padding-bottom 3rem
		border-bottom .1rem solid $borderColor

	&__name
		width 16rem
		flex-shrink 0
	&__desc
		padding-top 1rem
		ul
			margin-left 2rem
			li
				list-style disc outside
				margin-bottom .3rem
@media screen and (max-width 720px)
	.privileges
		&__table
			padding 1rem 0 0 0
		&__row
			flex-direction column
			margin-bottom 2rem
.asset-form
	.tab__header
		padding $modalPadding $modalPadding 0 $modalPadding
	.demo__api
		.tab__header
			padding 0 $modalPadding
	&__special-day-wrapper
		display flex
		width 100%
		height 100%
	&__opening-hours
	&__infos-and-location
	&__types-and-tags
	&__custom-fields
		fullwh()
		overflow-y auto
		padding 0 $modalPadding - .4 0 $modalPadding
	&__infos-and-location
		fullwh()
		display flex
	&__opening-hours
		&__timezone
			max-width 50%
			position relative
			margin-bottom 4rem
		&__default-value
			margin-bottom 1rem
			font-weight 700
		&__hours-format
			margin-bottom 1rem
		&__day
			display flex
			flex-direction column
			justify-content center
			min-height 8rem
			padding 2rem 2rem
			border-bottom .1rem solid $borderColor
			&:not(:last-child)
				border-bottom .1rem solid $borderColor
			&.everyday
				br()
				border .1rem solid $primary20
				background-color $primary6
				margin-bottom 1rem
			&__name
				min-width 12rem
				flex-shrink 0
				margin-bottom 0
			&__time-range-container
				mbi(.8)
				margin-top 2rem
				display flex
				align-items center
				flex-wrap wrap
				.btn--group-container
					.btn--group
						&:disabled
							background-color $light
							border-color $dark10
							.btn__label
								color $dark15
							&.active
								.btn__label
									color $light


						&.active
							background-color $success2
							border-color $success2
							&:disabled
								background-color rgba($success2, .4)
								border-color rgba($success2, .4)
			&__time-range
				flexSpread()
				mbi(1)
				br()
				height 4rem
				padding 0 1.4rem
				margin .4rem 0
				background-color $primary6
				input[type=text].input
					min-width 6rem
				&--disabled
					> :not(.input)
						opacity .4
		&__date-picker
			mbi()
			display flex
			align-items flex-start
			border-bottom .1rem solid $borderColor
			padding-bottom 2.4rem
			position relative
			&__inputs
				margin-top 2.7rem
				.input__item:not([type=button]):not([type=checkbox]):not([type=radio]):not([type=textarea])
					min-width 29rem
			.title
				margin-top 3rem
				flex-shrink 0
			.asset-form__fieldset__required-field
				right 1.8rem
		.rdt
			position absolute
			z-index 1
	&__custom-fields
		position relative
		&__fieldset
			mbi(1)
			margin-top 5rem
			display flex
			justify-content flex-start
			align-items flex-start
			max-width 40rem
			.input
				min-width 26rem
		&__values-container
			mbi(1)
			margin-top 2rem
			display flex
			align-items center
			flex-wrap wrap
		&__values
			flexBtn()
			br()
			mbi(.6)
			min-height $buttonHeightMini
			font-size $buttonFontSizeMini
			line-height 1.2
			padding .6rem $padding - .4rem .6rem $padding - .4rem
			background-color $primary20
			margin .2rem 0
			word-break break-all
			&__label
				.disabled &
					opacity .4
	&__map
		mbib(1)
		width 50%
		margin-left 1.6rem
		.mapboxgl-map
			fullw()
			height 80%
			min-height 0
	&__container
		position relative
	&__fieldset
		mbib(1.6rem)
		position relative
		width 50%
		margin-right 1.6rem
		&__name
			mbi(1)
			display flex
			> :nth-child(1)
				flex 1 1 0
			> :nth-child(2)
				flex 3 1 0
		&__address
			mbib(1)
			.select__placeholder
			.asyncselect__placeholder
				color $placeHolderColor !important
		&__two-cols
			mbi(1)
			fullw()
			display flex
			.input
				width 50%
		&__city
			mbi(1)
			display flex
			> :nth-child(1)
				flex 1 1 0
			> :nth-child(2)
				flex 1 1 0
			.select__label
				margin-bottom 1.1rem
		&__spacer
			height 2rem
		&__required-field
			position absolute
			right 0
			top 0
			color $secondary60
	&__types-and-tags
		mbib(5)
		&__inputs
			margin-top 2rem
			p
				margin-bottom 1rem
			.input
				min-width 26rem
				width 25%
	&__modal-header
		fullw()
		display flex
		&__left
		&__center
		&__right
			display flex
			align-items center
			flex-basis 100%
			flex-grow 1
		&__center
			justify-content center
		&__right
			justify-content flex-end
	&__success
		fullwh()
		flexAxis()
		&__container
			text-align center
			.title
				color $success
				font-size $title + 1
				line-height $title + 2
		&__message
			font-size $title2
			margin-bottom 4rem
		&__actions
			display flex
			align-items center
			justify-content center
			mbi(2)
		&__img
				margin 0 auto 2rem auto
				max-width 12rem
	.project__products
		padding-top 2rem
		padding-bottom 2rem
		.subtitle
			display none
	.rangepicker
		br()
		border .1rem solid $secondary15
		display flex
		flex-direction column
		.btn
			border-radius 0 0 .6rem .6rem
			justify-content center

	.rdrCalendarWrapper
		&.rdrDateRangeWrapper
			padding 0.6rem 0.8rem
			max-width 29rem
			width 100%
			.rdrMonthAndYearWrapper
				font-weight 700 !important
				font-size 1.8rem
				height 3.8rem
				margin-bottom 1rem
			.rdrNextPrevButton
				sq(3.8)
				br(50)
				trans()
				flexAxis()
				margin 0
				background-color rgba(106, 112, 127, 0.15)
				font-size 3rem
				font-weight 400
				color rgba(255, 255, 255, 0.01)
				&:hover
					background-color $primary15
				&.rdrNextButton
					&:hover
						i
							border-color transparent transparent transparent $primary
				&.rdrPprevButton
					&:hover
						i
							border-color transparent $primary transparent transparent 
				i
					trans()
					margin 0
			.rdrDayToday
				.rdrDayNumber
					span
						font-weight 900
						&:after
							background #dc3747
			.rdrWeekDay
				color $secondary
				font-size 1.4rem

			.rdrMonth
				width auto
				padding 0
			.rdrDayStartPreview
			.rdrDayEndPreview
			button.rdrDay
			.rdrDayNumber
			.rdrStartEdge
			.rdrEndEdge
				flexAxis()
				sq(3.8)
				br(50)
				font-size 1.4rem
				font-weight 900
			.rdrDayStartPreview
				sq(4.2)
				border-radius 50% 0 0 50%
				&.rdrDayEndPreview
					br(50)
			.rdrDayEndPreview
				sq(4.2)
				border-radius 0 50% 50% 0
			.rdrDayStartPreview
				left -.2rem
			.rdrDayEndPreview
				right -.2rem
			button.rdrDay
				height 4rem
			.rdrDayNumber
				br(50)
			.rdrDayHovered
				sq(4)
				br(50)
			.rdrDayEndPreview
			.rdrDayInPreview
				height 4.2rem
				top .3rem
				.rdrDayEndOfMonth
				.rdrDayEndOfWeek
					&:not(.rdrDayActive)
						.rdrInRange
						.rdrStartEdge
							border-radius 0 50% 50% 0
				.rdrDayStartOfWeek
				.rdrDayStartOfMonth
					&:not(.rdrDayActive)
						.rdrInRange
						.rdrEndEdge
							border-radius 50% 0 0 50%
			.rdrStartEdge
				border-radius 50% 0 0 50%
				left 0
				&.rdrEndEdge
					br(50)
			.rdrEndEdge
				border-radius 0 50% 50% 0
				right 0
			.rdrInRange
				sq(3.8)
				flexAxis()
			.rdrDayEndOfMonth
			.rdrDayEndOfWeek
				.rdrDayInPreview
					border-radius 0 50% 50% 0
					width 4.2rem
				.rdrInRange
				.rdrStartEdge
					border-radius 0 50% 50% 0
			.rdrDayStartOfWeek
			.rdrDayStartOfMonth
				.rdrDayInPreview
					border-radius 50% 0 0 50%
					width 4.2rem
				.rdrInRange
				.rdrEndEdge
					border-radius 50% 0 0 50%
					
				
@media screen and (max-width 720px)
	.asset-form
		.tab
			&__content
				margin-top 1.4rem
			&__header
				padding 1rem 1rem 0 1rem
		&__modal-header
			&__right
			&__center
				display none
			&__left
				width auto
				margin 0
		&__infos-and-location
		&__opening-hours
		&__types-and-tags
		&__custom-fields
			flex-direction column
			height 100%
			padding 1rem
		&__custom-fields
		&__types-and-tags
			.input
				min-width unset
				width 100%
		&__custom-fields
			&__fieldset
				mbib(1.4)
				margin-top 1rem
				max-width unset
				flex-direction column
		&__fieldset
		&__map
			width 100%
			margin 0
		&__fieldset
			&__name
			&__two-cols
			&__city
				mbib(1.4)
				flex-direction column
				.input
					width 100%
		&__opening-hours
			.asset-form__fieldset__required-field
				fullw()
				position relative
				top auto
				bottom 0
				text-align left
				margin 1rem 0 1rem 0
				order 2
			&__timezone
			&__date-picker
				flex-direction column
				display flex
				align-items flex-start
				flex-shrink 0
				min-height 10rem
				max-width 100%
				margin-bottom 1.4rem
			&__day
				mbib(1)
				padding 1rem
				flex-direction column
				align-items flex-start
				position relative
				&__time-range-container
					flex-direction column
					align-items flex-start
					.btn:not(.btn--group)
						sq(4)
						.icon
							sq(3.8)
				&--unusual
					.remove-unusual
						sq(4)
						position absolute
						top 1rem
						right 1rem
						.icon
							sq(3.8)
				&__time-range
					mbib(1)
					flex-shrink 0
					padding 1rem
					flex-direction column
					align-items center
					text-align center
					width 100%
					height auto
					margin 0 0 1.4rem !important
					.btn
						sq(3rem)
						.icon
							sq(2.8)
		&__success
			.modal__body
				height 100%
			&__container
				padding 1rem 1rem 6rem 1rem
				display flex
				flex-direction column
				height 100%
			&__img
				max-width 10rem
				margin 0 auto 2rem auto
				flex-shrink 0
			.title
				top -2rem
				font-size $title4
				flex-shrink 0
			&__message
				font-size $fontSize
				flex-shrink 0
			&__actions
				flex-shrink 0
				flex-direction column
				mbi(0)
				mbib(1.4)
				padding-bottom 4rem
@media screen and (max-width 460px)
	.asset-form
		&__opening-hours
			&__date-picker
				&__inputs
					margin 0 0 1.4rem 0 !important
					width 100%
				.datetime
					width 100%
					min-width 4rem
					.input__item
						min-width 4rem !important
					.rdtPicker
						min-width 24rem
						.rdtDay
							height 4.3rem
				.select
					margin-right 0
				.asset-form__fieldset__required-field
					position relative
					right auto
					margin 1rem 0 0 0 !important
.custom-timeframes
	&__date-range
		position relative

		&__day-passive
			visibility hidden
			cursor default

		&__next-button-disabled
			.rdrNextButton
				display none

		.rdrMonth
			width 20.667em

		.rdrWeekDays
			background $dark8

		.rdrMonthAndYearPickers
			display none

		.rdrMonthAndYearWrapper
			position absolute
			width 100%
			top 0
			height 30px

		.rdrMonthName
			text-align center
	
	&__date-inputs
		display flex
		
	&__predifined-timeframes
		display flex

.popover__content
	max-width 100%

.rdrStaticRanges
	max-height 400px
	overflow-y auto

.date-range-filter
	display flex
	align-content center
	border-right .1rem solid $borderColor
	height 100%
	cursor pointer
	flex-shrink 0
	.rdrMonth
		width 20.667em
	.rdrWeekDays
		background $dark8
	.rdrMonthAndYearPickers
		display none
	.rdrMonthName
		text-align center
	&__label
		display flex
		align-items center
		height 100%
		.icon
			transition none
			flex-shrink 0
		&__title
			margin-right 1rem
		&--hidden_label
			.label
				visibility hidden
		&__caret
			margin 0 2rem 0 1rem
.deviceslist
    width 100%
    height 100%
    display flex
    flex-direction column
    &__row
        border 1px solid black
        border-radius 10px
        display flex
        justify-content space-between
        flex-direction row
        margin-top 20px
        padding 10px
        width 100%
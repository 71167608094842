.member
	&__panel
		fullwh()
		display flex
		flex-direction column
		> *
			padding 0 $panelpadding 
		.panel__body__sub-title
			margin-bottom 3rem
@media screen and (max-width 720px)
	.member
		&__panel
			> *
				padding 0 1rem 2rem 1rem
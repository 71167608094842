.asset-details
	&__header
		fullw()
		flexSpread()
		&__title
			margin-right 2rem
		&__title
		&__actions
			flexMiddle()
			mbi()
		&__actions
			margin-left auto
	&__content
		fullwh()
		display flex
		overflow-y auto
		padding 0 $modalPadding - .4 0 $modalPadding
		&__left
		&__right
			position relative
			width 50%
			mbib(3)
		&__left
			margin-top 1.6rem
			margin-right 1.6rem
		&__right
			margin-top 1.6rem
			margin-left 1.6rem
	&__opening-hours
		&__closed
			mbi(4)
			display flex
			align-items center
			min-height 7rem
			&:not(:last-child)
				border-bottom .1rem solid $borderColor
		&__day
			mbi()
			display flex
			align-items center
			min-height 7rem
			&:not(:last-child)
				border-bottom .1rem solid $borderColor
			&__name
				display flex
				align-items center
				min-width 16rem
				flex-shrink 0
				mbi(1)
		&__time-range-container
			display flex
			align-items center
			flex-wrap wrap
			mbi()
		&__time
		&__time-range
			display flex
			align-items center
			mbi(.8)
		&__time-range
			margin .4rem 0
		&__time
			span
				color $secondary-medium60
	&__information
		&__location
			margin-bottom 3rem
			&__subtitles
				display flex
				justify-content space-between
				.subtitle
					color $secondary60
					font-weight 400
		&__coord-container
			display flex
			flex-wrap wrap
			mbi()
		&__coord
			mbi(.8)
			display flex
			align-items center
		&__map
			width 100%
			height 40rem
			margin 1.6rem 0
		&__detail-container
			margin-bottom 1.6rem
		&__detail
			mbi(.8)
			display flex
			align-items center
			word-break break-all
			margin-bottom .8rem
			flex-wrap nowrap
			.asset-details__information__detail-container &
				flex-wrap wrap
			&__title
				font-weight 700
				min-width 4rem
			.icon
				flex-shrink 0
	&__popover
		padding 1rem 2rem 2rem 1rem
		width 30rem
		mbi(1)
		.btn
			font-size $fontSize
		&__labels
			mbi(.2)
			display flex
			align-items center
			flex-wrap wrap
			margin-bottom 1rem
	.asset-form__custom-fields__values
		&-container
			margin-top 1rem
			margin-bottom 1.6rem
	.asset-details__opening-hours__day
		min-height 6rem
@media screen and (max-width 720px)
	.asset-details
		.modal__header
			h1
				font-size $title5
			.title
				lex-direction column
				align-items flex-start
				line-height 1.2
				> :nth-child(1)
					font-size $fontSizeSmall
					margin-bottom 1rem
				.icon
					display none
		&__header
			&__actions
				display none
			&__title
				flex-direction column
				align-items flex-start
				line-height 1.2
				> :nth-child(1)
					font-size $fontSizeSmall
					margin-bottom 1rem
				.icon
					display none
		&__content
			padding 1rem
			flex-direction column
			&__left
			&__right
				margin 0
				width 100%
		&__opening-hours
			&__day
				flex-wrap wrap
				min-height unset
				padding 1rem 1.6rem
		&__information
			&__detail
				flex-direction column
				margin-bottom 1.6rem
				&__title
					display block
					margin-bottom 1rem

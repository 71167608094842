.pois
	&__list
		position relative
		.list
			all unset
			&__item--previous-item
			&__item--previous-item .list__row
			&__item
			&__row
			&__col
			&__cols
			&__col--fill
				all unset
			&__row
				display revert
				background-color unset !important
				border 0 !important
			&__item--previous-item .list__row
			&__col
			&__row
				display flex
				flex-direction column
				align-items flex-start
				flex-grow 0
		li
			display flex
			flex-direction column
			flex-grow 0
			align-items flex-start
		&__item
			mbi(2)
			br()
			trans()
			min-height $buttonHeight
			padding .6rem 1.2rem
			display flex
			flex-grow 0
			cursor pointer
			&:hover
				background-color $dark3
				.pois__action
					opacity .4
					&--expand
						opacity 1
			&.venue
				.pois__name
					font-weight 600
					font-size $title3
					padding-top .6rem
				.pois__action
					margin auto 0
				&:hover
					background-color unset
					cursor default !important
			&.venue
			&.level
				margin-bottom 1rem
			&.building
				margin-bottom .5rem
			&.building
			&.level
				position relative
				padding .7rem 1.2rem .7rem 4rem

				&:before
					content ""
					position absolute
					width .1rem
					height .9rem
					background-color $secondary40
					left 1.5rem
					top 1.4rem
				&:after
					content ""
					position absolute
					width .9rem
					height .1rem
					background-color $secondary40
					left 1.1rem
					top 1.8rem
				&.opened
					&:before
						display none

		ul
			mbib(.6)
			margin-left 1.1rem
			position relative
		ul ul
			margin-left 3rem
	&__action
		opacity 0
		margin-left auto
		height 2.3rem !important
	&__name
		padding .2rem 0
	&__fieldset
		mbib()
		&__images
			display flex
			flex-wrap wrap
			margin-bottom 4rem !important
		&__logo
			background-image url(../../../../images/indoor/default-logo.png)
			width 10rem
			height 10rem
		&__cover
			background-image url(../../../../images/indoor/default-cover.png)
			width 26rem
			height 12.9rem
			margin-right 3rem
		&__logo
		&__cover
			br()
			trans()
			position relative
			display flex
			align-items center
			justify-content center
			background-size contain
			background-repeat no-repeat
			border .1rem solid $dark6
			cursor pointer
			margin-bottom 1.5rem
			&:hover
				.pois__fieldset__cover__download
				.pois__fieldset__cover__cache
					opacity 1
			&__cache
				br()
				position absolute
				width 100%
				height 100%
				background-color $dark30
				opacity 0
				z-index 0
			&__download
				sq(4)
				br(50)
				trans()
				z-index 10
				display flex
				align-items center
				justify-content center
				background-color $dark90
				box-shadow 0 0 2rem $dark30
				opacity 0
				.icon
					stroke $light
		&--style
			.input
				width auto
				margin-bottom 1.6rem
				&--color
					.input__item
						overflow hidden
						max-width 6rem
						min-width 0 !important
				&--number
					.input__item
						overflow hidden
						max-width 6rem
						min-width 0 !important
	&__zoom
		mbi(3)
		display flex
		flex-wrap wrap
	&__custom-properties
		mbib()
		padding 0
		&__warning
			background-color $flashBorderWarning
	&__icon
		flex-shrink 0
		stroke-width .18rem
	&__switch
		mbib(1.6)
		margin-bottom 3rem


.team
	&__panel
		fullwh()
		display flex
		flex-direction column
		> *
			padding 0 $panelpadding
		.navtab
			margin-bottom 3rem
		.list
			padding $panelpadding 0 0 0
@media screen and (max-width 720px)
	.team
		&__panel
			.navtab
				padding-bottom 0
				mbi(1.6)
				.btn
					height $buttonHeight
			.select
				padding-bottom 0
			> *
				padding 0 1rem 2rem 1rem
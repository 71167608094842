/*
We declare all variables here as default so that they are easy to override...
*/
/* Border, shadows, ... */
/*
Variables that begin with $var- should NEVER be used directly in CSS rules.
they should only be included via the "@include var(property, var-name)"
mechanism.
*/
/* Border styles */
/* Cookie notice positioning */
/* Text colors */
/* Font Families */
/* White */
/* Differently shaded colors */
/*
Use @include var(property, varname) to include a variable, e.g.
  
    @include var(background-color, white1);

to create a white background.
*/
/* Spacing */
/* Breakpoints */
.klaro
	font-family inherit
	font-family var(--font-family, inherit)
	font-size 14px
	font-size var(--font-size, 14px)
	button
		font-family inherit
		font-family var(--font-family, inherit)
		font-size 14px
		font-size var(--font-size, 14px)
	&.cm-as-context-notice
		height 100%
		padding-bottom 12px
		padding-top 12px
	.cookie-modal
	.context-notice
	.cookie-notice
		.cm-switch-container
			border-bottom-style solid
			border-bottom-style var(--border-style, solid)
			border-bottom-width 1px
			border-bottom-width var(--border-width, 1px)
			border-bottom-color #c8c8c8
			border-bottom-color var(--light2, #c8c8c8)
			display block
			position relative
			padding 10px
			padding-left 66px
			line-height 20px
			vertical-align middle
			min-height 40px
			&:last-child
				border-bottom 0
			&:first-child
				margin-top 0
			p
				margin-top 0
		.cm-switch
			position relative
			display inline-block
			width 50px
			height 30px

		.cm-list-input
			&:checked + .cm-list-label .slider
				background-color #1a936f
				background-color var(--green1, #1a936f)
			&.half-checked
				&:checked + .cm-list-label .slider
					background-color #1a936f
					background-color var(--green1, #1a936f)
					opacity 0.6
				&:checked + .cm-list-label .slider
					&:before
						transform translateX(10px)

			&.only-required + .cm-list-label .slider
				background-color #24cc9a
				background-color var(--green2, #24cc9a)
				opacity 0.8
			&:before
				transform translateX(10px)

			&.required:checked + .cm-list-label .slider
				background-color #24cc9a
				background-color var(--green2, #24cc9a)
				opacity 0.8
				cursor not-allowed

		.cm-list-input
			position absolute
			top 0
			left 0
			opacity 0
			width 50px
			height 30px

		.cm-list-title
			font-size 0.9em
			font-weight 600
			cursor pointer
		.cm-list-description
			color #7c7c7c
			color var(--dark3, #7c7c7c)
			font-size 0.9em
			padding-top 4px

		.cm-list-label
			.cm-switch
				position absolute
				left 0
			.slider
				background-color #f2f2f2
				background-color var(--white2, #f2f2f2)
				position absolute
				cursor pointer
				top 0
				left 0
				right 0
				bottom 0
				transition 0.4s
				width 39px
				display inline-block
				height 18px
				&:before
					background-color #e6e6e6
					background-color var(--white3, #e6e6e6)
					position absolute
					content ''
					height 14px
					width 14px
					left 3px
					bottom 2px
					transition 0.4s
				&.round
					border-radius 30px
					&:before
						border-radius 50%
			input
				&:checked + .slider::before
					transform translateX(20px)
		.cm-list-input
			&:checked + .cm-list-label .slider
				&:before
					transform translateX(20px)
		

		a
			color $success2
			color var(--green1, $success2)
			text-decoration none

		p
		strong
		h1
		h2
		ul
		li
			color $secondary
			display block
			text-align left
			margin 0
			padding 0
			margin-top 0.7em

		h1
		h2
		h3
		h4
		h5
		h6
			font-family inherit
			font-family var(--title-font-family, inherit)

		.cm-link
			margin-right 0.5em
			vertical-align middle

		.cm-btn
			color #fff
			color var(--button-text-color, #fff)
			background-color #5c5c5c
			background-color var(--dark2, #5c5c5c)
			border-radius 6px
			border-radius var(--border-radius, 6px)
			padding 6px 10px
			margin-right 0.5em
			border-style none
			padding 0.4em
			font-size 1em
			cursor pointer
			&:disabled
				opacity 0.5
			&.cm-btn-close
				background-color #c8c8c8
				background-color var(--light2, #c8c8c8)
			&.cm-btn-success
				background-color #1a936f
				background-color var(--green1, #1a936f)
				&-var
					background-color #24cc9a
					background-color var(--green2, #24cc9a)

			&.cm-btn-info
				color $secondary
				background-color transparent
				padding 0
				text-decoration underline
				margin 0 0 20px
				transition .2s all
				&:hover
					color $primary
				&.cm-btn-accept
					text-decoration none
					color $light
					margin-bottom 0
	.context-notice
		border-radius 6px
		border-radius var(--border-radius, 6px)
		border-style solid
		border-style var(--border-style, solid)
		border-width 1px
		border-width var(--border-width, 1px)
		border-color #c8c8c8
		border-color var(--light2, #c8c8c8)
		background-color $secondary
		display flex
		flex-direction column
		flex-wrap wrap
		align-items center
		justify-content center
		padding 12px
		height 100%
		&.cm-dark
			background-color $light
			border-color #5c5c5c
			border-color var(--dark2, #5c5c5c)
			p
				color $secondary
				a
					color #459cdc
					color var(--blue2, #459cdc)
		p
			color #333
			color $light
			flex-grow 0
			text-align center
			padding-top 0
			margin-top 0
			a
				color $success2
				color var(--green2, $success2)

			&.cm-buttons
				margin-top 12px

	.cookie-modal
		width 100%
		height calc(100% - 6rem)
		position fixed
		overflow hidden
		left 0
		top 0
		z-index 1000
		padding 1rem
		&.cm-embedded
			position relative
			height inherit
			width inherit
			left inherit
			right inherit
			z-index 0
			.cm-modal.cm-klaro
				position relative
				-ms-transform none
				transform none

		.cm-bg
			background $dark50
			height 100%
			width 100%
			position fixed
			top 0
			left 0

		.cm-modal.cm-klaro
			background-color $light
			color $secondary
			z-index 1001
			box-shadow 0 4px 6px 0 rgba(0, 0, 0, 0.2), 5px 5px 10px 0 rgba(0, 0, 0, 0.19)
			width 100%
			max-height 98%
			top 50%
			-ms-transform translateY(-50%)
			transform translateY(-50%)
			position fixed
			overflow auto

@media (min-width 0px)
	.klaro
		.cookie-modal
			.cm-modal
				&.cm-klaro
					border-radius 6px
					border-radius var(--border-radius, 6px)
					position relative
					margin 0 auto
					max-width 640px
					height auto
					width auto
				.hide
					border-style none
					background none
					cursor pointer
					position absolute
					top 20px
					right 20px
					z-index 1
					svg
						stroke $secondary
						stroke var(--light1, $secondary)

				.cm-footer
					border-top-color #5c5c5c
					border-top-color var(--dark2, #5c5c5c)
					border-top-width 1px
					border-top-width var(--border-width, 1px)
					border-top-style solid
					border-top-style var(--border-style, solid)
					padding 2rem 3rem
					&-buttons
						display flex
						flex-flow row
						justify-content space-between
					.cm-powered-by
						font-size 0.8em
						padding-top 4px
						text-align right
						padding-right 8px
						a
							color #5c5c5c
							color var(--dark2, #5c5c5c)

				.cm-header
					border-bottom-width 1px
					border-bottom-width var(--border-width, 1px)
					border-bottom-style solid
					border-bottom-style var(--border-style, solid)
					border-bottom-color #5c5c5c
					border-bottom-color var(--dark2, #5c5c5c)
					padding 2rem 3rem
					padding-right 24px
					font-size $fontSize
					line-height $fontSize * 1.4
					h1
						margin 0 !important
						font-size 2rem !important
						line-height 3rem !important
						display block
						&.title
							padding-right 20px

				.cm-body
					padding 0 3rem 2rem 3rem
					.cm-list-label
						display block
						margin-bottom .4rem
					ul
						display block
						font-size $fontSize
						&.cm-services
						&.cm-purposes
							mbib(2)
							li
								&.cm-purpose
									.cm-services
										.cm-caret
											color #a0a0a0
											color var(--light3, #a0a0a0)
										.cm-content
											margin-left -40px
											display none
											&.expanded
												margin-top 10px
												display block
								&.cm-service
								&.cm-purpose
									position relative
									line-height 20px
									vertical-align middle
									padding-left 60px
									min-height 40px
									&:first-child
										margin-top 0
									p
										margin-top 0
										&.purposes
											color #a0a0a0
											color var(--light3, #a0a0a0)
											font-size 0.8em
									&.cm-toggle-all
										border-top-width 1px
										border-top-width var(--border-width, 1px)
										border-top-style solid
										border-top-style var(--border-style, solid)
										border-top-color #5c5c5c
										border-top-color var(--dark2, #5c5c5c)
										padding-top 1em
									span
									&.cm-list-title
										font-weight 600
										color $secondary
									&.cm-opt-out
									&.cm-required
										color #5c5c5c
										color var(--dark2, #5c5c5c)
										padding-left 0.2em
										font-size 0.8em
									.cm-caret
										font-size 1.2rem
					span
						display inline-block
						width auto

	.cookie-notice
		&:not(.cookie-modal-notice)
			background-color $light
			z-index 2000
			position fixed
			width 100%
			bottom 0
			right 0
			line-height 2rem

@media (min-width 1024px)
	.klaro
		.cookie-notice
			&:not(.cookie-modal-notice)
				border-radius 6px
				border-radius var(--border-radius, 6px)
				position fixed
				position var(--notice-position, fixed)
				right 20px
				right var(--notice-right, 20px)
				left auto
				left var(--notice-left, auto)
				bottom 20px
				bottom var(--notice-bottom, 20px)
				top auto
				top var(--notice-top, auto)
				max-width 400px
				max-width var(--notice-max-width, 400px)
				box-shadow 0 4px 6px 0 rgba(0, 0, 0, 0.2), 5px 5px 10px 0 rgba(0, 0, 0, 0.19)

@media (max-width 1023px)
	.klaro
		.cookie-notice
			&:not(.cookie-modal-notice)
				border-style none
				border-radius 0 !important
.klaro
	.cookie-notice
		&:not(.cookie-modal-notice)
			&.cn-embedded
				position relative
				height inherit
				width inherit
				left inherit
				right inherit
				bottom inherit
				z-index 0
				.cn-body
					padding-top 0.5em
			.cn-body
				margin-bottom 0
				margin-right 0
				bottom 0
				padding 1em
				padding-top 0
				p
					margin-bottom 0.5em
					&.cn-changes
						text-decoration underline

				.cn-learn-more
					display inline-block
					flex-grow 1

				.cn-buttons
					display inline-block
					margin-top -0.5em

@media (max-width 384px)
	.klaro
		.cookie-notice
			&:not(.cookie-modal-notice)
				.cn-body
					.cn-buttons
						width 100%
.klaro
	.cookie-modal-notice
		background-color $light
		color $secondary
		z-index 1001
		box-shadow 0 4px 20px 0 $dark10
		width 100%
		max-height 98%
		top 50%
		-ms-transform translateY(-50%)
		transform translateY(-50%)
		position fixed
		overflow auto
		padding 1em
		padding-top 0.2em
		.cn-ok
			display flex
			flex-flow column
			justify-content space-between
			margin-top 1em
			text-align right
		.cn-buttons
			width 100%
			display flex
			justify-content space-between
	.cookie-notice-hidden
		display none !important
	.cookie-notice
		&:not(.cookie-modal-notice)
			.cn-body
				.cm-link
					&.cn-learn-more
						text-align right
				.cn-buttons
					width 100%
					display flex
					justify-content space-between
					button
						&.cm-btn
							margin-top 0.5em
						

@media (max-width 384px)
	.klaro
		.cookie-notice
			&:not(.cookie-modal-notice)
				.cn-body
					.cn-buttons
						button
							&.cm-btn
								width calc(50% - 0.5em)
	.cookie-notice
		&:not(.cookie-modal-notice)
			.cn-body
				.cn-ok
					margin-top -0.5em
					display flex
					flex-direction column
					flex-wrap wrap
					a
					div
						margin-top 0.5em


// Klaro override serge

.klaro
	.cookie-notice
	.cookie-modal
	.context-notice
		a
			color $secondary
			text-decoration underline
		.cm-btn
			&.cm-btn-success
			&.cn-decline
				button()
				br()
				textShadow()
				background-color $primary
				font-size $fontSize
				padding $buttonPadding
				min-height $buttonHeight
				transition .2s all
			&.cn-decline
				background transparent
				border 1px solid $dark20
				border-radius 6px
				color $dark40
				text-shadow unset
				&:hover
					background-color $logo8
					color $light
			&.cm-btn-success
				&:hover
					background-color $logo8
					color $light
		.cm-list-input:checked + .cm-list-label .slider
			background-color $success2
		.cm-list-input.required:checked + .cm-list-label .slider
			opacity .4
		.cm-list-label
			.slider
				background-color #aaa
				&:before
					background-color $light
		.cm-list-description
		p.purposes
			color $light
	.cookie-modal
		font-size $fontSize
		.cm-modal
			.cm-body
				ul
					&.cm-services
					&.cm-purposes
						li
							&.cm-service
							&.cm-purpose
								span
									&.cm-opt-out
									&.cm-required
										color $light80
										font-size $fontSize
									&.cm-list-title
										font-size $fontSize
								&.cm-toggle-all
									border-color $light30
								p
									&.purposes
										color $light
			&.cm-klaro
				background-color $light
				br()
			.cm-header
				border-color $light30
			.cm-footer
				border-color $light30
				.cm-powered-by
					display none
@media (min-width 400px)
	.klaro
		.cookie-modal-notice
			border-radius 6px
			border-radius var(--border-radius, 6px)
			position relative
			margin 0 auto
			max-width 400px
			height auto
			width auto
			padding 20px 30px 20px 30px
			left 0
@media (max-width 399px)
	.klaro
		.cookie-notice
		.cookie-modal
		.context-notice
			.cm-btn
				&.cm-btn-success
				&.cn-decline
					padding 0 8px
					white-space normal



@media (max-width 384px)
	.klaro
		.cookie-notice
			&:not(.cookie-modal-notice)
				.cn-body
					.cn-buttons
						button.cm-btn
							width 100%
							box-sizing border-box
							text-align center

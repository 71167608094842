.project-security
	mbib(3)
	&__list
		margin-bottom 1rem
		&.list
			padding 0
		.list__col--no-result
			br()
			background-color $dark3
			padding 1rem 1.6rem
		&__name
			max-width 30rem
			width auto
			flex-shrink 0
		&__rights
			mbi(.4)
			display flex
			align-items center
			width auto
			flex-shrink 0
			overflow visible !important
			.tooltip
				&:before
				&:after
					text-transform capitalize
			&__badge
			&__label
				flexAxis()
				textShadow()
				color $light
				cursor pointer
				font-size .9rem
				font-weight 600
				text-transform uppercase
			&__badge
				br(50)
				sq(2.4)
				background-color #99a1d0
				&--write
					background-color #bfa584
			&__label
				br(.4)
				line-height 1
				height 2.4rem
				padding 0 1rem
				background-color #3dc651
				&--no-restriction
					sq(2.4)
					background-color transparent
					padding 0
					margin-left .4rem
					.icon
						stroke red
						stroke-width .15rem
						circle
							r .045rem
							cy 1.7rem !important
		&__key
			min-width 12rem
	&__keys-container
		mbib(2)
	&__add-domain-form
		mbib()
		&__fieldset
			mbi()
			display flex
			align-items flex-start
			.btn
				position relative
				top .6rem
	&__add-security-key-modal
	&__security-key-modal
		fullwh()
		mbib(2)
		min-height 30rem
		.asset-form__custom-fields
			padding 0
			overflow visible
			&__fieldset
				max-width 100%
				margin 0
				.input
					position relative
					&__error
						position absolute
						bottom -2.4rem
	&__add-security-key-modal
		min-height 38rem
		.input--checkbox
			margin-bottom 2rem
.manage-domains
	.flash
		margin 1rem 0

@media screen and (max-width 720px)
	.project-security
		&.content__body__section
			padding-top 2rem
		&__list
			padding 0 !important
			.list__col--action
				display flex
				.tooltip
				.dropdown
					display block
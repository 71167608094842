.products
	.content__body__section
		margin-bottom 4rem
	.content__body__container
		fullwh()
		flexCol()
	.demo
		height unset
	&__lauk-tos
		margin-bottom 1rem

	&__line
		&-separator
			height .1rem
			border-top .1rem solid $borderColor
			margin-bottom 3rem
		&-name
			margin 1rem 0
			color $secondary
			text-transform uppercase
			font-size $fontSizeSmall - .1
			letter-spacing .2rem
		&__list
			margin-bottom 3.6rem
	&__section
		&__label
			display flex
			align-items center
			margin-bottom 3rem
			&__title
				br()
				flex-shrink 0
				white-space nowrap
				padding .4rem 1.2rem
				background-color $primary10
				margin 0 2rem 0 0
				font-size $fontSizeSmall
				font-weight 400
				letter-spacing .1rem
				text-transform uppercase
				.products__section__label--featured &
					background-color $secondary
					color $light
			&__line
				fullw()
				height .1rem
				background-color $borderColor
				.select-project-products &
					display none
	&__cost
		margin-bottom 3rem
	&__demos
		position relative
		overflow auto
		z-index 1
		fullwh()
		.for-mobile
			display none
		&__addproject
			& .modal__body
				overflow visible
			& .select__multi-value__remove
				display none
		> .tab
			padding 0 $contentPadding
		.demo
			&__content-wrapper
				padding 0
			&--search
			&--localities
				.demo__header
					display none
		.card
			padding $contentPadding $contentPadding 0 $contentPadding
			margin-bottom 1rem
			min-height 25rem
			&__image
				width 28rem
			&__img
				border .1rem solid $borderColor
			&__header
				&__title
					font-size $title + .5
			.documentation
				margin-left .4rem
		&__addons
			margin 0
			.card
				padding 0
				&__image
					width 17.5rem
				&__content
					padding 0
					margin-left 2rem
				&__header
					&__title
						font-size $title4
						line-height 1.4
				&__aside
					&__list__item__section__paying-label
						margin-top .4rem
	&__market
		&.content__body__container
			padding-top 0
		&__list
			display flex
			flex-wrap wrap
			mbi(3)
			&__item
				margin-top 1rem
				margin-bottom 1rem
				&__info
					display flex
					align-items center
					color $secondary60
					margin-top .4rem
					mbi(.4)
					.badge
						&--small
							margin-right .6rem
					.btn--link
						color $secondary60
	&__activated
		&__autocomplete
			padding 0 $modalPadding
		.modal
			&__content
				height 100%
			&__body
				display flex
				flex-direction column
				overflow hidden
				padding 0
				mbib(2)
				.list
					padding 0 $modalPadding
				.no_projects
					display: flex
					flex-direction column
					align-items center
					line-height 4rem

@media screen and (min-width 960px)
	.products
		.content__body__section--featured
			.card
				width auto
				flex-direction row
				border 0
				cursor pointer
				&__image
					width 32rem
				&__img
					br(1)
					width 32rem
				&__body
					&__desc
						height auto
						max-height 100%
						-webkit-line-clamp 7
@media screen and (max-width 940px)
	.products__market
		&__list
			mbi(1.6rem)
@media screen and (max-width 860px)
	.products
		&__demos
			.card
				flex-direction column
				padding 1rem
				&__image
					width auto
				&__img
					margin 0
					max-width 30rem
				&__content
					padding 0
					margin-top 2rem
@media screen and (max-width 820px)
	.products
		&__market
			&__list
				mbi(0)
@media screen and (max-width 720px)
	.products
		&__demos
			.for-desktop
				display none
			.for-mobile
				display flex
			.card
				&__img
					max-width 30rem
					margin auto
				.btn
					display none
			.tab
				padding 0 1rem
				&__header__buttons
					mbi(2)
					.btn--tab
						height $buttonHeightSmall
						.btn__label
							margin-top 0
							font-size 1.2rem
		&__market
			&__list
				justify-content center
				mbi(0)
				&__item
					margin 0
			.card
				margin 1rem
				width 100%
				max-width 26rem
		&__section
			&__label
				margin-bottom 1.6rem
@media screen and (max-width 400px)
	.products
		&__market
			.card
				margin 0 0 2rem 0
				width 100%
				max-width unset
	
.dropzone
	br()
	trans()
	display flex
	align-items center
	justify-content center
	width 100%
	background-color $secondary-light40
	border-width .2rem
	border-style dashed
	border-color $secondary-light
	&--reject
		border-color $error
		background-color $error30
		.btn
			text-decoration none !important
	&--accept
		border-color $success
		background-color $success50
		.btn
			text-decoration none !important
	&-wrapper
		width 100%
		margin-bottom 2rem
	&__btn
		width 100%
		padding 2rem
		justify-content center


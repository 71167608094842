.settings
	& .content__body
		position relative
	&__section
		margin-bottom 3rem
		mbib()
		&--small
			max-width 20rem
		&__title
			color $secondary40
			margin-bottom 1.2rem
		&--boxed
			br()
			flexCol()
			.btn
				margin-left auto
			.password-strength
				width 100%
		&__input-and-button
			fullw()
			mbi()
			display flex
			margin-bottom 4rem
			
	.content
		&__body__container
			padding 0 $contentPadding $contentPadding $contentPadding
		&__sidebar__body
			height 100%
			padding-bottom 2rem
	&__mfa
		mbib(4)
		&--apps
			display flex
			mbib(4)
		&__apps
			display flex
			flex-direction column
			img
				max-width 22rem
			&__badge
				max-height 4rem
		&__add-mobile
			.form
				fullwh()
				flexAxis()
				padding 0 $modalPadding
			&__wrapper
				display flex
				max-width 76%
			&__section
				display flex
				align-items flex-start
				flex-direction column
				min-height 30rem
				width 50%
				padding 0 3rem
				mbib(3)
			&__qr
				margin auto
	&__device-list
		&.content__body__container
			padding 0
		&__token-list
			margin-bottom 3rem
			&__item
				list-style none
				padding-left 1rem
				margin-left 1rem
				&:before
					font-size .8rem
					position relative
					left -1rem
					top - .2rem
					content "\2022"
					color $secondary-medium60

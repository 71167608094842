.omnisearch
	&__modal
		background rgba(0, 11, 31, .7)
		.modal
			&__wrapper
				align-items flex-start
				padding 2rem 1.7rem
			&__content
				box-shadow unset
				margin 0 0 0 18.2rem
				transition 1s all ease-in
			&__body
				padding 0
				max-height 60vh
				height 100%
				display flex
				flex-direction column
				overflow hidden
				.btn
					text-decoration none
					&__label
						strong
							background-color rgba($resultMatch, 20%)
				li
					display flex
					align-items center
					margin 0 0 0 .4rem
					height 3.2rem
		&--centered
			.modal
				&__content
						margin-left auto
						margin-right auto

	&__header
		mbi()
		display flex
		align-items center
		flex-shrink 0
		padding-right 2rem
		border-bottom .1rem solid $borderColor
		height 5rem
		.input
			.icon
				sq(1.8)
				top .9rem
		input[type="search"]::-webkit-search-cancel-button
			display none

	&__title-input
		margin-right 3rem
		flex-grow 1
		display flex
		align-items center
		.content__body__title
			width 12.1rem
	&__input
		max-width 42rem
		margin-right auto
		.input__item
				padding-left 3.6rem
				background-color $secondary-light10 !important
		.icon
			sq(1.8)
			top .9rem
	&__result
		&__wrapper
			height 100%
			overflow-y auto
		&__item
			padding 2rem
			border-bottom .1rem solid $borderColor
		&__category
			display flex
			align-items center
			font-size 1.2rem
			color $secondary-medium80
		&__counter
			br()
			sq(1.8)
			margin-left 1rem
			display flex
			align-items center
			justify-content center
			background-color $secondary
			font-size .9rem
			font-weight 700
			color $light
		&__button
			br(.3)
			padding .3rem .5rem
			height unset
			border unset
			line-height 1.1
			&:hover
				color $dark !important
				border unset
				background-color $secondary-light40 !important
		&__separator
			margin 0 .3rem
	&__loadmore
		margin-left .4rem
		padding 0 !important
		.icon
			top .5rem
	&__category
		padding-left 2rem
		height 6rem
		display flex
		align-items center
		flex-shrink 0
		border-bottom .1rem solid $borderColor
		overflow hidden
		.title
			margin-bottom 0
			margin-left 2rem
	&__noresult
		padding 2rem 2rem
		color $dark40
@media screen and (max-width 720px)
	.omnisearch
		&__modal
			.modal
				&__content
					margin 0
				&__header
					min-height unset
		

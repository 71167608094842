.fake
	position relative
	&-item
		br()
		trans()
		background-color #fff
		border .1rem solid $secondary20
		cursor pointer
		margin 0 auto 1rem auto
		.content__sidebar &
			flexCol()
			padding 1.6rem
			width 100%
			height 11rem
		.content__body &
		.modal__body &
			display flex
			align-items center
			width 100%
			height 4.8rem
			padding 0 1.8rem
			margin 0 auto 1rem auto
		&__line-spacer
			width 100%
		&__line-title
		&__line-subtitle
		&__line-content
		&__line-detail
			br(3)
			height 1.4rem
			flex-shrink 0
		&__line-title
			background-color $dark8
			.content__sidebar &
				flex-shrink 0
				margin-bottom 1rem
		&__line-subtitle
		&__line-content
		&__line-detail
			background-color $dark3
			.content__body &
			.modal__body &
				margin-left auto
			.content__sidebar &
				margin-bottom 2rem
				height .8rem
		&__line-content
		&__line-detail
		&__line-spacer
			.content__sidebar &
				display none
		&__line-subtitle
		&__line-content
			.content__body &
			.modal__body &
				margin-right 12%
		&__product-container
			display flex
			mbi(-.2)
			.content__body &
			.modal__body &
				display none
		&__line-spacer
			width 100%
		&__line-subtitle
			.content__body &
			.modal__body &
				width 9% !important
		&__line-content
			.content__body &
			.modal__body &
				width 7%  !important
		&__line-detail
			.content__body &
			.modal__body &
				width 4%  !important
		&__product-item
			br(50)
			sq(2.4)
			flex-shrink 0
			background-color #ebebeb
			&:nth-child(1)
			&:nth-child(5)
				background-color #ebebeb
			&:nth-child(2)
			&:nth-child(6)
				background-color #efefef
			&:nth-child(3)
			&:nth-child(7)
				background-color #ededed
			&:nth-child(4)
			&:nth-child(8)
				background-color #f1f1f1
	&:nth-child(1)
	&:nth-child(4)
	&:nth-child(7)
	&:nth-child(10)
	&:nth-child(13)
	&:nth-child(16)
	&:nth-child(19)
		.fake-item
			&__product-item
				&:nth-child(1)
				&:nth-child(2)
					display none
	&:nth-child(2)
	&:nth-child(5)
	&:nth-child(8)
	&:nth-child(11)
	&:nth-child(14)
	&:nth-child(17)
	&:nth-child(20)
		.fake-item
			&__product-item
				&:nth-child(1)
				&:nth-child(2)
				&:nth-child(3)
				&:nth-child(4)
					display none
	&:nth-child(3)
	&:nth-child(6)
	&:nth-child(9)
	&:nth-child(12)
	&:nth-child(15)
	&:nth-child(18)
	&:nth-child(21)
		.fake-item
			&__product-item
				&:nth-child(1)
				&:nth-child(2)
				&:nth-child(3)
				&:nth-child(4)
				&:nth-child(5)
				&:nth-child(6)
					display none
@media screen and (max-width 1090px)
	.fake-item
		.content__sidebar &
			height 10.2rem
		&__product-container
			mbi(.2)
		&__product-item
			sq(1.6)

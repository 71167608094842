.locator
	&__create-modal
		.modal
			&__body
				padding 3rem
				width 100%
			&__content
				br()
		&__wrapper
			mbib(4)
	&__modal
		height 100%
		.mapboxgl-map
			height 100%
			width 100%
		.modal__header
		.title
			mbi()
			display flex
			align-items center
		.modal
			&__header
				min-height 7.5rem
			&__body
				overflow hidden
	&__wrapper
		fullwh()
		display flex
		position relative
		.btn--manage-asset
			position absolute
			top 2rem
			right 2rem
			border .1rem solid $light
			box-shadow 0 0 2rem $dark20
	&__map
		height 100%
		flex-grow 1
		position relative
	&__main
		fullwh()
	&__aside
		height 100%
		width 100%
		border-left .1rem solid $borderColor
		display flex
		flex-direction column
		overflow hidden
		max-width 33%
		.btn
			margin-left auto
		&__header
			display flex
			align-items center
			justify-content space-between
			flex-direction column
			position relative
			height 100%
			&.simple
				height auto
				.title
					height 7rem
					padding-left 2rem
					width 100%
					border-bottom .1rem solid $borderColor
					margin-bottom 0
				.btn--close
					position absolute
					top 2rem
					right 1rem
			.btn--close
				position absolute
				top 1.4rem
				right 1rem
			.tab
				&__header
					padding 2rem 2rem 0 2rem
					border-bottom .1rem solid $borderColor
				&__content
					margin-top 0
		&__body
			height 100%
			display flex
			flex-direction column
			&__actions
				mbi(3)
				display flex
				align-items center
				min-height 7.4rem
				background-color $dark2
				padding 0 2rem
				border-bottom .1rem solid $borderColor
			&__option
				padding 2rem
				border-bottom .1rem solid $borderColor
				min-height 15rem
			&__content
				flex-grow 1
				overflow hidden
			&__feature
				flex-grow 1
				overflow-y auto
			pre
				overflow hidden
				height 100%
				width 100%
				padding 0 !important
				margin 0 !important
				color $logo5
				background-color $dark4 !important
				code
					overflow auto
					display block
					height 100%
					padding 2rem
					padding-bottom 4rem
					background transparent
					white-space pre-wrap !important
		&--reduced
			display flex
			width 6rem
			padding 1rem
			overflow visible
			.locator__aside__header
				mbib(2)
				height auto
	&__intro
		&__desc
			padding 2rem
		&__image
			width 100%
			height 20rem
			background-size cover
			background-position 50% 50%
			box-shadow inset 0 0 5rem $dark30
	&__option
		display flex
		align-items flex-start
		width 100%
		padding 3rem
		border-bottom .1rem solid $borderColor
		&__image
			br()
			max-width 8rem
			width 100%
			margin-right 2rem
		&__body
			flex-grow 1
		&__name
			display flex
			justify-content space-between
			align-items flex-start
		&__desc
			margin-bottom 2rem
		&__code
			pre
				br()
			


@media screen and (max-width 720px)
	.locator
		&__modal
			.modal__header
				h1
					font-size $title5
				.title
					flex-direction column
					align-items flex-start
					line-height 1.2
					> :nth-child(1)
						font-size $fontSizeSmall
						margin-bottom 1rem
					.icon
						display none
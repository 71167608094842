header()
	flexSpread()
	flex-shrink 0
	margin 2rem $contentSidebarPadding $contentSidebarPadding $contentSidebarPadding
	.title
		margin-bottom 0
.project-disabled
	background #fff url('../images/texture.png')
.content
	fullwh()
	display flex
	flex-grow 1
	.popover__child
		display flex
		align-items center
	&__sidebar
		trans()
		width $contentSidebar
		height 100%
		background-color $primary6
		// background-image linear-gradient(180deg, $primary2 50%, $primary6 100%)
		// box-shadow inset 0 0 1rem $primary20
		border-right .1rem solid $borderColor
		flex-shrink 0
		&.view-list
			width 45rem
			trans()
		&__wrapper
			flexColStretch()
			fullwh()
		&__header
			header()
			.title
				.tooltip
					position relative
					top -1rem
					margin-left 1rem
					cursor pointer
					font-size $fontSize !important
				.sso-google
					sq(2.4)
					br(50)
					display flex
					align-items center
					justify-content center
					background-color $secondary60
					.icon
						stroke $light
		&__org-name
			margin 2rem $contentSidebarPadding 0 $contentSidebarPadding
		&__filters
			flexMiddle()
			mbi(.2)
			padding 0 $contentSidebarPadding
			flex-shrink 0
			margin-bottom 1.6rem
			.input
				width 100%
				margin-right 1rem
		&__main-button
			margin-bottom 3rem
		&__list
			position relative
		.list
			padding 0 $contentSidebarPadding
			&__row
				all unset
				display revert
				margin-bottom 1rem
	&__header
		header()
	&__body
		fullwh()
		flex-grow 1
		display flex
		flex-direction column
		.list:not(.no-padding)
			padding 2rem $contentPadding 0 $contentPadding
		&__header
			flexSpread()
			flex-shrink 0
			height $contentHeaderHeight
			padding 0 $contentPadding
			border-bottom .1rem solid $borderColor
			.project-disabled &
				color $secondary60
				.btn
					.icon:not(.favorites__project)
						stroke $secondary60
			&--no-border
				border 0
			&__title-with-action
				flexMiddle()
				mbi(2)
				.btn--back
					display none !important
			&__right-action
				flexMiddle()
				mbi(1)
				white-space nowrap
			&__title
			&__title-with-action
				fullw()
				min-width 8rem
				.title
					ellipsis()
					margin-bottom 0
			&__org-name
				margin 2rem $contentSidebarPadding + .4 0 $contentSidebarPadding + .4
		&__menu
			padding 0 $contentPadding
			position relative
			.project-disabled &
				opacity .6
			&:before
				content ''
				position absolute
				height 0.1rem
				border-bottom 0.1rem solid $borderColor
				width 100%
				bottom 0.1rem
				left 0
		&__container
			display flex
			flex-direction column
			justify-content flex-start
			height 100%
			min-height 0
			padding-top 3rem
			overflow-y auto
			&.projects-assets
			&.projects-venues
				padding-top 0
		&__title-tab-menu
			display flex
			align-items center
		&__titles-container
			mbi(3)
			display flex
			margin-right 2rem
		&__title
			margin-bottom 0
			flex-shrink 0
			.icon
				flex-shrink 0
			&--link
				a
					color $secondary-medium60
					text-decoration none
					&:hover
						color $secondary
		&__section-grid
			display flex
			fullwh()
			overflow hidden
			&__scrollable
				overflow auto
				border-right .1rem solid $borderColor
				fullwh()
			&__left
				overflow hidden
				display flex
				flex-direction column
				width 100%
				height 100%
			&__right
				width 35rem
				height 100%
				flex-shrink 0
				overflow auto
		&__section
			fullw()
			padding 0 $contentPadding
			margin-bottom 2rem
			.projects-assets &
			.projects-venues &
				padding-top 1rem
				padding-bottom 2rem
				margin-bottom 0
				justify-content flex-start
			&.grey-bg
				background-color $dark3
			&--empty
				margin-top 7rem
				display flex
				justify-content center
				flex-direction column
				align-items center
				&__actions
					display flex
					justify-content center
					flex-direction column
					align-items center
				.no-data
					font-size $title3
			&-with-action
				display flex
				align-items center
			&__container
				padding 0 .6rem
			&__simple-title
				margin-top 2rem
			&__title
				fullw()
				flexMiddle()
				flex-shrink 0
				margin-bottom 1.6rem
				.content__body__section-with-action &
					margin-bottom 0
					width auto
					min-width 15rem
				.title
					margin-bottom 0
					ellipsis()

				.icon
					flex-shrink 0
				.input
				.select
					margin-left auto
					min-width 20rem
					width auto
			&--featured
				.content__body__section
					padding 0 !important
					margin-bottom 0
					.products__section__label
						display none
			&__info
				text-align center
				margin 1.4rem 0 1.1rem 0
				color $secondary-medium60
			dl
				dt
					mbi(.6)
					display flex
					align-items center
					font-weight 700
					margin-bottom .8rem
				dd
					margin-bottom 2rem
					padding-left 1.4rem
					color $secondary-medium60
			&__filters
				flexSpread()
				mbi()
				margin 0 4rem
				border-bottom .1rem solid $inputBorderColor
				width 100%
				&:focus
				&:focus-within
					border-bottom .1rem solid $secondary
				.projects-venues &
					border 0
			&__select
			&__actions
				position relative
				flexMiddle()
				mbi(1)
				flex-shrink 0
				flex-wrap nowrap
			&__select
				mbi(3)
				height 5rem
				.title
					margin-bottom 0
@media screen and (max-width 1325px)
	.content__body__section-grid__right
		width 30rem
@media screen and (max-width 1090px)
	.content
		&__sidebar
			width $contentSidebar - 6
			&.view-list
				width $contentSidebar
			.input__item:not([type=button]):not([type=checkbox]):not([type=radio])
				min-width 100%
			.card__new-label
				font-size $fontSizeSmall - .4 !important
				top .2rem
				right .2rem
				padding .2rem .3rem !important
				height auto !important
				line-height 1 !important
		&__body
			&__section
				&__filters
					margin 0 1.6rem
@media screen and (min-width 720px) and (max-width 1090px)
	.content
		&__sidebar
			.simplecard
				&__content
					.label
						trans()
						sq(1.6)
						span
							display none
				.tooltip
					&:first-child
					&:nth-child(2)
					&:nth-child(3)
					&:nth-child(4)
					&:nth-child(5)
					&:nth-child(6)
					&:nth-child(7)
					&:nth-child(8)
						left auto
						margin-left .2rem
@media screen and (max-width 1024px)
	.projects
		.content__body__section
			&-with-action
				&.content__body__section
					flex-wrap wrap !important
					flex-direction row !important
					align-items center !important
					flex-grow 0
			&__title
				order 1
			&__filters
				order 3
				margin-left 0
				margin-right 0
				margin-top .4rem
			&__actions
				order 2
				margin-left auto
				margin-right 0

@media screen and (max-width 999px)
	.content__body
		&__section
			&-grid
				display flex
				flex-direction column
				height auto
				overflow auto
				&__scrollable
					border 0
				&__right
				&__left
					width 100%
					min-width auto
					height auto
					overflow unset
		&__header
			.flash
				display none
@media screen and (max-width 960px)
	.content
		&__body
			&__section
				flex-direction column
				flex-grow 1
			&__section-with-action
				align-items flex-start
				justify-content flex-start
				.btn--edit-assets
					display none !important

@media screen and (max-width 880px)
	.content
		&__body
			&__header
			&__menu
				padding 0 1.6rem

@media screen and (max-width 720px)
	.content
		height calc(100% - 6rem)
		&__sidebar
			height 100%
			width 100%
			z-index 1000
			background-color $contentSideBar
			.products &
				display none
			&__main-button
				padding 0 1rem
				margin-bottom .5rem
				.simplecard
					padding 1rem 1.6rem
			&__header
				margin 1rem 1.6rem
			&--displayformobile
				display none
			&__filters
				padding 0 1rem
				.input
					margin-right 0
				.tooltip
					display none

		&__body
			&__header
				padding 1rem
				height auto
				position sticky
				top 0
				z-index 10
				background-color $light
				&__title
					text-align center
					margin-right 0 !important
					.dashboard__title
						font-size $title2
				&__title-with-action
					.dashboard__title
						font-size $title2
					.dropdown
						display inline-block
						margin-left auto
						&__menu.s
							right 9rem
					.btn--back
						display inline-flex !important
						margin-right 1rem
				&__stats
					width 100%
			&__title
				.dashboard &
					font-size $title3
			&__menu
				padding 0 1.6rem
			&__container
				padding 0
				width 100%
				&.teams-and-auth
					padding 1.4rem 1.4rem 0 1.4rem
			&__main
			&__aside
				position inherit
			&__section
				padding 0 1rem
				&-with-action
					.projects &
						mbib(1)
						padding-bottom 0 !important
				.products &
					margin-bottom 2rem
				&--empty
					margin-top 0
				&__container
					padding 0
					&.products-list
						.project-overview &
							flex-direction column
							.card
								margin 0 0 1rem 0 !important
								width 100%
				&__title
					background-color transparent
					padding 0
					box-shadow none
					border 0
					
			&__section-with-action
				position relative
				.content__body__section__title
					margin-bottom 0
					height 2.8rem // hack
					border .1rem solid transparent // hack
					margin-top 0
					.icon
						display none
					
				.content__body__section__actions
					.btn--multi-options
						display flex
						.dropdown
							display flex
					.btn--secondary
						padding 0 0.6rem !important
						.btn__label
							display none
						.icon
							margin-right 0 !important
				.content__body__section__filters
					margin-top 0
@media screen and (max-width 350px)
	.content__body__menu
		padding 0 1rem !important
#root
	width 100%
	height 100%
body
	scroll-behavior smooth
// fix for safari
canvas
	font-family $fontFamily
	font-size 62.5%

h1
	font-size $title
	font-weight 700
	line-height $title * 2 - .6
	margin-bottom 1rem

h2
	font-weight 700
	font-size $title2
	line-height $title2 * 2 - .6
	margin-bottom 1rem

h3
	font-weight 700
	font-size $title3
	line-height $title3 * 2 - .6
	margin-bottom 1rem

h4
	font-weight 700
	font-size $title4
	line-height $title4 * 2 - .6
	margin-bottom 1rem

h5
	font-weight 700
	font-size $title5
	line-height $title5 * 2 - .6
	margin-bottom 1rem

h6
	font-weight 700
	font-size $title6
	line-height $title6 * 2 - .6
	margin-bottom 1rem

p
	margin-bottom 1.6rem

.italic
	font-style italic
.fontSizeSmall
	font-size $fontSizeSmall
.dark20
	color $dark20
.dark40
	color $dark40
.dark50
	color $dark50
.dark60
	color $dark60
.grow
	flex-grow 1
.bold
strong
	font-weight 700

a
	text-decoration underline
	cursor pointer
	trans()
.link
	color $logo2
	text-decoration underline
	cursor pointer
	&:hover
		color $logo5
.borbot
	border-bottom .1rem solid $borderColor
	padding-bottom 1rem
	margin-bottom 1rem
.title
	&--help
		mbi(1)
		display flex
		align-item center

.no-data
.loading
	color $secondary40

.no-data
	text-align center
	&--image
		margin 10rem auto
		display flex
		justify-content center
		align-items center
		flex-direction column
		text-align center
		background #fbfbfb url('../images/texture.png')
		border-radius 0.6rem
		padding 4rem 2rem
		box-shadow inset 0 0 20rem $light
		border 1px solid $dark4
		img
			max-width 12rem
			margin-bottom 3rem

.center
	text-align center

.intercom
	&-lightweight-app
	&-launcher-frame
	&-namespace .intercom-kzixrd
		z-index 1 !important
	&-lightweight-app-launcher
		bottom 4rem !important

.react-codemirror2
	height 100%

.cursor-default
	cursor default
.cursor-pointer
	cursor pointer
.grey
	color $secondary40
.orange
	color $warning
	.icon
		stroke $warning
.demo
	width 100%

.bullet-point
	mbib(.6)
	margin-left 2rem
	li
		list-style disc inside

.tbl
	br()
	box-shadow 0 0 .5rem $dark12
	&__tr
		display flex
		align-content center
		flex-direction column
		padding 1.8rem 2.6rem
		font-weight 600
		&--header
		&--footer
			padding 2.6rem
			> :first-child
				margin-right 2rem
				width 20rem
				flex-shrink 0
		> *
			display flex
			align-items center
		&:first-child
			flex-direction row
			justify-content space-between
			border-bottom .1rem solid $dark5
			color $secondary60
		&:last-child
			justify-content space-between
			flex-direction row
			border-top .1rem solid $dark5
			background-color $dark2
			font-size $title
		&.tbl
			&__tr
				&--orange
					color $warning
				&--red
					color $special2
				&--fill
					color white
					background-color $special2
	&__colspan
		mbi(3)
		display flex
		align-items center
		justify-content space-between
		width 100%
		max-width 32rem
		margin-left auto
	&__line
		width 100%
		display flex
		align-items center
		flex-direction column
		flex-grow 1
		&:not(:last-child)
			margin-bottom 1.6rem
		&__details
		&__main
			width 100%
			display flex
			align-items center
			justify-content space-between
		&__main
			margin-bottom 1rem
			> :first-child
				margin-right 2rem
				width 20rem
				flex-shrink 0
		&__details
			flex-direction column
			&__item
				font-size $fontSizeSmall
				font-weight 400
				color $secondary60
				display flex
				align-items center
				justify-content space-between
				width 100%
				margin-bottom .6rem
				> :first-child
					margin-right 2rem
					width 20rem
					flex-shrink 0
.table
	br()
	border-collapse collapse
	width 100%
	overflow hidden
	box-shadow 0 0 .1rem .1rem $secondary-medium25
	margin-bottom 2rem

	th
		font-weight 700

	th
	td
		padding 1.4rem 2rem

	tr
		border-bottom .1rem solid $secondary-medium15

		td
		th
			width 100%

			&:last-child
				min-width 20rem
				text-align center
				font-weight 700

	thead
		tr
			&:nth-child(odd)
				background-color $primary10

	tbody
		tr
			&:nth-child(even)
				background-color $primary6

.trendimg
	width: 2rem

.modal__stepper__error
	padding 1rem 3.6rem
	text-align center
	background-color $modalStepperError
	color $error

input[type="search"]::-webkit-search-cancel-button
	cursor pointer

.shortcut-list
	fullwh()
	.modal__body
		overflow hidden
		display flex
		flex-direction column
	.list
		padding $modalPadding $modalPadding 0 $modalPadding
input::-webkit-contacts-auto-fill-button, 
input::-webkit-credentials-auto-fill-button {
  visibility: hidden;
  position: absolute;
  right: 0;
}

.draggable
	padding .6rem
	width 25rem
	height 40rem
	overflow-y auto
	&-item
		br(.3)
		trans()
		user-select none
		padding .8rem
		margin-bottom .4rem
		display flex
		align-items center
		.icon
			stroke $dark40
		&:hover
			color $dark
			.icon
				stroke $dark


.panel
	&--orgs
		width $panelWidth + 20
		z-index 1070
	&__wrapper
		fullwh()
		display flex
		flex-direction column
		> *
			padding 0 2.4rem
	&__subs
		li
			div
				br()
				padding .8rem 1.2rem
				cursor pointer
				background-color $dark6
				border .1rem solid $dark20
				color $dark
				&:hover
					background-color $primary6
					border-color $logo2
					color $logo2
				
	&__list-actions
		br()
		overflow hidden
		li
			display flex
			align-items center
			justify-content space-between
			padding 1rem
			&:nth-child(odd)
				background-color $dark3
			&:nth-child(even)
				background-color $dark6
			.progress__small
				margin-top unset
	&__content-header-actions
		display flex
		align-items center
		justify-content space-between
		margin-bottom 1.6rem
		h3
			margin-bottom 0
	.shortcut-list
		height 17rem
		flex-shrink 0
		border-bottom .1rem solid $dark6
		.activity-list
			padding 0 !important
			margin 0 !important
			overflow-y auto !important
			li
				height 4rem !important
				position relative !important
			.list
				&__row
					padding 0 !important
				&__cols
					flex-wrap wrap
				&__col
					width 100% !important
					min-width unset !important
					max-width unset !important
					text-align left
					&:first-child
						font-weight bold
		
	.switch__container
		flex-shrink 0
	.label
		&--true
		&--false
			br()
			display flex
			align-items center
			justify-content center
			padding .2rem .6rem
			font-size 1.2rem
		&--true
			background-color lighten($success, 90%)
			border .1rem solid lighten($success, 50%)
			color $success
		&--false
			background-color $dark6
			border .1rem solid $dark20
@media screen and (max-width 767px)
	body
		.embeddedServiceHelpButton
			.helpButton
				bottom 6.7rem
		.embeddedServiceSidebar
			&.layout-docked
				.dockableContainer
					bottom 5rem
			.embeddedServiceSidebarMinimizedDefaultUI
				br(3)
				bottom 6.7rem
				border .1rem solid $light
				&.minimizedContainer
					&:focus
						text-decoration none
		.sf-chat
			&__button
				bottom 6.7rem
				position relative
			&__wrapper
				bottom 5rem
				position relative


	.intercom-lightweight-app
		display none
	.table
		tr
			td
			th
				&:last-child
					min-width 16rem

#embeddedMessagingFrame.isMaximized
	width 430px

.flex
	&-col
		display flex
		flex-direction column;

.dashboard
	&__title
		font-size $title + .4rem

	.content__body
		&__header
			width 100%
			justify-content flex-start
			mbi(6)

			&__title-with-action
				width auto
				flex-shrink 1
			.flash
				min-width 0
				margin-left 2.8rem
				margin-right 1.3rem
				opacity .6
				flex-shrink 1
				span
					ellipsis()

	&__indicators
		&__container
			min-width 0
			mbi(2)
			display flex
			align-items center
			justify-content flex-end
			flex-grow 1
		&__box
			trans()
			br()
			mbi()
			text-decoration none
			display flex
			align-items center
			padding 0 1.6rem
			height 5rem
			width 100%
			flex-shrink 1
			border .1rem solid $dark10
			cursor pointer
			background $light
			&:hover
				background-color $success2
				color $light
			&__stat
				font-size $title + 1.2
				line-height $title + 1.2
				font-weight 700
			&__footer
				mbi(.3)
				font-weight 700
				font-size $fontSizeSmall - .1
				.icon
					sq(.8)
					stroke $light
	&__metrics
		&__body
			padding 1.6rem 2.6rem 0 2.6rem
		&__overall
			position sticky
			top 0
			z-index 10
			background-color $light
			&__box
				.title
				.subtitle
					margin-bottom 0
				.subtitle
					font-size $title3
				&__body
					.select__control
						min-width 18rem
					&--top
					&--bottom
						display flex
						justify-content space-between
						flex-wrap wrap
					&--top
						padding 1rem 2.6rem
						background-color $dark3
						align-items center
						.btn--group
							&-container
								margin-right 2rem
							&.active
								background-color $primary
								border-color $primary
								&:hover
									background-color $primary
									border-color $primary
							&:hover
								background-color $special3
								border-color  $special3
								color $light
								textShadow()
					&--bottom
						mbi(4)
						align-items flex-end
						padding 0 2.6rem
						border-top .1rem solid $borderColor
						border-bottom .1rem solid $borderColor
						.metric-picker
							margin 1rem 4rem 1rem auto
					&__content
						display flex
						flex-wrap wrap
				&__product-filter
					margin 0 0 1rem 0
				&__queries
					display flex
					&-amount
						br()
						mbi(1)
						textShadow()
						display flex
						align-items center
						font-weight 700
						height $buttonHeightSmall
						padding 0 1.2rem
						font-size $title2
						font-weight 700
						margin-left 2rem
						background-color $success2
						color $light
						.icon
							stroke $light
						&__no-data
							font-size $fontSize
		&__header
			mbi(4)
			display flex
			align-items center
			margin-bottom 2rem
			.title
				margin-bottom 0
		&__chart
			br()
			fullw()
			padding 1rem 0
			margin-bottom 2rem
			min-height 58rem
			canvas
				max-height $canvasMaxHeight - 10
			.project-metrics
				&__chart
					padding 0
					margin-bottom 4rem
				&__legend
					max-width 100%
					padding 0
					&-container
						padding 0
						margin-bottom 0
					&__label__queries-container
						margin-right 2rem
					&__item
						br()
						trans()
						margin-bottom .6rem
						background-color $primary6
						padding 1rem 1rem 1rem 2rem
						&:hover
							background-color $primary15
			&__pager
				mbi()
				display flex
				margin-bottom 2rem
				&__control
					mbi(.3)
					display flex
					align-items center
					line-height 1
					font-size $title5
	
	&__products
		padding 0 $contentPadding
		margin-bottom 4rem
		.content__body__section-grid__right &
			padding revert
			margin revert
		&__header
			.content__body__section-grid__right &
				padding $contentPadding
			display flex
			align-items center
			justify-content space-between
			&__title
				margin-bottom 3rem
				.content__body__section-grid__right &
					margin-bottom 1rem
		&__list
			.content__body__section-grid__right &
				padding 0 $contentPadding $contentPadding $contentPadding
			ul
				display flex
				flex-wrap wrap
				.content__body__section-grid__right &
					flex-direction column
				li
					flex-shrink 0
					width 50%
					.content__body__section-grid__right &
						width 100%
					&:nth-child(odd)
						.dashboard__products__list__item
							padding-right 2rem
							.content__body__section-grid__right &
								padding-right 0
					&:nth-child(even)
						.dashboard__products__list__item
							padding-left 2rem
							.content__body__section-grid__right &
								padding-left 0
			&__item
				width 100%
				margin-bottom 3rem
	&__trial
		&__header
			display flex
			align-items center
			border-bottom .1rem solid $borderColor
			padding 1.6rem $contentPadding 1.6rem $contentPadding
			height 10.6rem
			&__section
				&:nth-child(odd)
					width 25%
					margin-right 4rem
				&:nth-child(even)
					width 75%
				&__title
					font-weight 400
					color $secondary-medium60
					margin-bottom 0
					white-space nowrap
				&__info
					font-weight 700
					margin-bottom 0
		&__body
			fullwh()
			display flex
			flex-direction column
			justify-content center
			align-items center
			padding $contentPadding
		&__consmuption-legend
			margin-top 2rem
			font-weight 700
			mbi(.2)
			display flex
			> :first-child
				margin-right 1rem
		.tab
			background-color $dark3
			&__header
				padding $contentPadding $contentPadding 1.6rem $contentPadding
			&__content
				margin-top 0
			canvas
				width 26rem !important
				height 26rem !important
	&__consumption
	&__projects
		&__header
			padding $contentPadding - 1 $contentPadding
			display flex
			align-items center
			justify-content space-between
			&__title
				margin-bottom 0
	&__consumption
		padding-bottom 1.6rem
		border-bottom  .1rem solid $borderColor
		.update-date
			font-size 1rem
			color $dark40
			padding 1rem 2rem 0 2rem
			text-align center
		&__body
			br()
			margin 0 $contentPadding $contentPadding - 1 $contentPadding
			border .1rem solid $borderColor
			overflow hidden
			&__header
				width 100%
				display flex
				align-items center
				justify-content space-between
				padding 0 2rem
				min-height 5rem
				border-bottom .1rem solid $borderColor
				> :first-child
					font-weight 600
					.enterprise &
						font-weight unset
				.dashboard__consumption__body--limit &
					background-color $special2
					color $light
					.icon
						margin-right 1rem
						stroke $light
			&__content
			&__content-addons
				padding 0 $contentPadding
				min-height 9rem
				display flex
				align-items center
				justify-content center
				&__billable
					border-top .1rem solid $borderColor
					padding 1.4rem 2rem
					text-align center
					width 70%
					margin auto
					font-size $fontSizeSmall
				&__free-count
				&__billable-count
					font-size $title + 1
					line-height $title * 2
					font-weight bold
					text-align center
				&__billable-count
					margin-bottom 2rem
				.dashboard__consumption__body--limit &
					color $special2
				&__header
				&__footer
					width 100%
					display flex
					align-items center
					justify-content center
				&__header
					padding 2rem 2rem 0 2rem
					&--standard
						padding 1.4rem 2rem 1.4rem 2rem
						border-bottom .1rem solid $borderColor
					&--free
						padding 2rem 2rem 1rem 2rem
					&--billabled
						font-weight 600
				&__footer
					padding 0 2rem 2rem 2rem
					.dashboard__consumption__body--limit &
						color $special2
					.enterprise &
						.progress
							margin-top 1.4rem
				&__count-addons
				&__count
					font-size $title + 3
					line-height $title * 3
					font-weight bold
					margin-bottom 1rem
					.enterprise &
						margin-bottom 0
				&__count-addons
					margin-bottom 2rem
				.enterprise &
					display flex
					flex-direction column
					gap .4rem
			&__content-addons
				min-height 7rem
		&__footer
			padding 0 $contentPadding $contentPadding - 1 $contentPadding

	&__projects
		&__body
			padding 0 $contentPadding $contentPadding - 1 $contentPadding
			&--top-projects
				&__list
					mbib()
				&__card
					text-decoration none
			&--last-created
				padding 0 $contentPadding $contentPadding $contentPadding
				ul
					mbib()
		&__item
			br()
			position relative
			padding 2rem
			background $metricsTopProjects
			border .1rem solid $dark10
			.dashboard__projects__body--last-created &
				background $metricsLastCreatedProjects
			a
				text-decoration none
				color $dark
		&__title
			margin-bottom 0
		&__name
			margin-bottom .6rem
			font-weight 400
		&__queries
			color $secondary50
			font-weight 700
			font-size $title6
@media screen and (max-width 1325px)
	.dashboard
		&__projects
			&__header
				padding 2rem 1.6rem
			&__body
				&--top-projects
				&--last-created
					padding 0 1.6rem 2rem 1.6rem
		&__consumption
			&__header
				padding 2rem 1.6rem
			&__body
				margin 0 1.6rem 2rem 1.6rem
		&__trial
			&__body
				padding 1.6rem
			.tab
				canvas
					width 26rem !important
					height 26rem !important
@media screen and (max-width 999px)
	.dashboard
		&__projects
			display flex
			&__container
				flex-grow 1
		&__trial
			&__body
				padding 1.6rem
@media screen and (max-width 960px)
	.dashboard
		.project-metrics
			&__donut
				margin 2rem 0 3rem 0
			&__legend
				margin 0
		&__metrics__chart
			.project-metrics__legend__item
				max-width 100%

@media screen and (max-width 860px)
	.dashboard
		.content__body
			&__header
				padding 1rem 1.6rem
				justify-content space-between
				mbi(0)
			&__title
				font-size $title
		&__metrics
			&__body
				padding 1.6rem
			&__overall
				flex-direction column
				mbib(1)
				&__box
					padding 0
					color $secondary
					background-color transparent
					border 0
					&__body
						&--top
							padding 1rem 1.6rem
						&--bottom
							padding-left 1.6rem
							padding-right 1.6rem
		&__indicators
			&__box
				padding 0 1rem
				&__stat
					mbi()
					font-size $title
					line-height $title
		

@media screen and (max-width 820px)
	.dashboard
		&__indicators
			&__container
			&__box
				mbi(.6)
		&__metrics
			.title
				font-size $title4
			&__chart
				padding 1rem
				br(1)
		&__projects
			&__header
				padding 1rem 1.6rem
				&__title
					font-size $title4
					margin-bottom 0
			&__body
				padding 1rem 1.6rem
				margin-bottom 1rem
@media screen and (max-width 605px)
	.dashboard
		&__metrics
			&__overall
				&__box
					.subtitle
						font-size $title6
					&__body
						&--top
							align-items flex-start
						&--bottom
							flex-wrap wrap
							.metric-picker
								margin-right auto !important
								width 100%
						&__content
							flex-direction column
					&__queries
						&-amount
							font-size $title3
							margin-left 1rem
				.label
					fullw()
					display flex
					flex-grow 1
					flex-shrink 1
					&__name
						ellipsis()
						flex-grow 1
@media screen and (max-width 580px)
	.dashboard
		&__title
			font-size $title3
		&__indicators
			&__container
				flex-direction column
				align-items flex-end
				width unset
				margin-left 2rem
			&__box
				mbi(.3)
				background-color transparent
				padding 0
				color $secondary
				text-shadow none
				border 0
				height auto
				&.members
				&.team
					background-color transparent
				&__stat
					font-weight bold
					font-size $fontSize
				&__footer
					display none
				&__label
					display flex
		&__projects
			flex-direction column
			&__container
				width 100%
		&__metrics
			&__overall
				&__box
					&__content
						width 100%
					&__body
						&--bottom
							flex-direction column
							align-items flex-start
							justify-content center
							.metric-picker
								margin-right auto !important
								margin-bottom 1rem
								width 100%
					&__product-filter
						width 100%

@media screen and (max-width 420px)
	.dashboard
		&__indicators
			&__box
				&__stat
				&__label
					font-size $fontSizeSmall
		.project-metrics
			&__legend
				&__item
					margin-bottom .6rem
					br()
					padding 1rem
					border .1rem solid $borderColor
					background-color $light

@media screen and (max-width 420px)
	.dashboard__metrics__overall
		.metrics-picker
			width 100%
			margin-right 0 !important
			&.label
				flex-grow 1

@media screen and (max-width 315px)
	.dashboard__metrics__overall
		.metrics-picker
			width 100%
			margin-right 0 !important
			&.label
				flex-grow 1
		&__box__queries
			margin-bottom 1rem
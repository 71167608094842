.multiple-assets
	&__selection-mode
		mbi(2)
		margin-left 12rem
		flex-grow 1
		display flex
		align-items center
		.selection-label
			font-weight 600
	.modal
		&__body
			overflow hidden
			display flex
	.tab-content
		display flex
		width 100%
	.list
		padding $modalPadding $modalPadding 0 $modalPadding
	.inactive
		display none
	&__wrapper
		display flex
		height 100%
		width 100%
		overflow hidden
	&__filters
	&__list
		width 50%
		height 100%
	&__list
		display flex
		flex-direction column
		padding 2rem 0 0 2rem
		overflow hidden
		&__title
			flex-shrink 0
			margin-bottom 0
			min-width 6rem
		.scrollbar-container
			padding 1.6rem 2rem 0 0
	&__filters
		overflow-y auto
		border-right .1rem solid $borderColor
		&__wrapper
			display flex
			flex-direction column
			.clear-all
				margin 2rem 2rem 0 2rem
				margin-left auto
		&__line
		&__actions
			display flex
			padding 2rem
			flex-shrink 0
		&__label
			margin 1rem 3rem 2rem 0
			font-weight 600
			min-width 7rem
			white-space nowrap
			flex-shrink 0
		.input__item
			min-width 8rem !important
		&__line
			mbi(3)
			border-bottom .1rem solid $borderColor
			flex-wrap wrap
			.btn
				&.add
					margin-left 2rem
				&.delete
					border-left 0
					border-radius 0 $borderRadius $borderRadius 0 !important
					&:disabled
						border-color $inputBorderColor !important
						opacity 1 !important
						.icon
							stroke $dark15 !important
			.or-label
				display flex
				align-items center
				height $buttonHeight
				margin-right 2rem
				font-weight 600

			&__items
				mbib(1)
				flex-grow 1
				.btn
					margin-left auto
			&__fieldset
				display flex
				width 100%
		&__actions
			&__buttons
				mbi(1)
				flex-grow 1
				display flex
	&__actions
		&__buttons
			flex-grow 1
			display flex
			&__apply-values
				mbi(1)
				flex-grow 1
				display flex
				align-items center
		&__edit
			padding 0 2rem 2rem 2rem
	&__delete-confirmation
	&__download-status
		mbi(2)
		display flex
		align-items center
	&__download-status
		margin-top 6rem
		justify-content center
	&__edit
		padding 0 2rem 2rem 2rem
		display flex
		flex-direction column
		&__wrapper
			display flex
			flex-direction column
		&__line
			br()
			mbib()
			position relative
			border .1rem solid $borderColor
			display flex
			flex-grow 1
			flex-direction column
			margin-bottom 2rem
			padding 2rem
		&__select
			mbi()
			display flex
			flex-grow 1
			.btn
				&.delete-range
					margin-left auto
			.rdt
				position absolute
				z-index 1
				top .2rem
				left .3rem
				.rdtPicker
					box()
					.rdtSwitch
						font-size $fontSize
					.rdtNext
					.rdtPrev
						width 3rem !important
						height 3rem !important
						> span
							width 3rem !important
							height 3rem !important
							&:before
								top .2rem
					.rdtDay
					.dow
						font-size $fontSizeSmall
					.rdtDay
						width 1rem
						height 2rem
						padding 0
						border-radius $borderRadius
			.date-selected
				display flex
				align-items center
				&--label
					display flex
					align-items center
					text-align center
					padding 0 2rem
					font-weight 700
					border .1rem solid $inputBorderColor
					height $buttonHeight
					border-radius $borderRadius 0 0 $borderRadius
					border-right 0
				&--remove
					border-radius  0 $borderRadius $borderRadius 0 !important
		&__range-wrapper
			mbi(3)
			display flex
			flex-grow 1
			flex-wrap wrap
		&__hours-range
			min-height 0
			margin-bottom 1.6rem
			display flex
			align-items center
			.range
				&--from
				&--to
					display flex
					align-items center
					text-align center
					padding 0 1rem
					border .1rem solid $inputBorderColor
					height $buttonHeight
				&--from
					border-radius $borderRadius 0 0 $borderRadius
					border-right 0
				&--to
					border-radius 0 !important
					border-width .1rem 0 .1rem 0
			.hour
				&--from
				&--to
					.input__item
						border-radius 0 !important
			.input
				width 8rem
				&__item
					text-align center
					min-width 0 !important
			.btn
				&.delete-range
					border-radius 0 $borderRadius $borderRadius 0 !important
					border-left 0
				&.add-range
					margin-left 2rem
	.select
		width auto
		&__indicator
			padding-left .6rem !important
			padding-right .6rem !important
			&-separator
				display none
		&__control
			&--is-focused
				border-color $inputBorderColor !important
			&--is-disabled
				&:not(.select__control--is-focused):not(.asyncselect__control--is-focused)
					border-color $dark5 !important
					background-color $dark3
				.select
				.asyncselect
					&__single-value
						color $dark20  !important
	.operators
		.select
			&__control
				width 7rem
				min-width unset
				border-radius 0 !important
				border-left 0
				border-right 0

	.value
		width 100%
		.select
			&__control
				border-radius 0 !important

	.attibute
		.select
			&__control
				min-width 12rem
				max-width 20rem
				border-radius $borderRadius 0 0 $borderRadius !important
				flex-grow 1
	.new-value
		.input
			&__item
				flex-grow 1
				border-radius 0 !important
				&:focus
					border-color $inputBorderColor !important
	.change-to
		flex-grow 1
	&__manual-list
		width 40%
		height 100%
		overflow hidden
		border-right .1rem solid $borderColor
		display flex
		flex-direction column
		.list__check
			flex-shrink 0
		&__header
			padding 2rem 2rem 0 2rem
			flex-shrink 0
		&__search
			flex-shrink 0
			border-bottom .1rem solid $inputBorderColor
			margin-bottom 2rem
		.scrollbar-container
			padding 0 2rem 0 2rem
	&__manual-actions
		flex-grow 1
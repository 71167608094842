.profile
	&__authentication
		display flex
		&__account
			width 65%
			min-width 36rem
			margin-right 3rem
			padding-right 3rem
			border-right .1rem solid $borderColor
			&__iframe
				margin-top 1rem
				margin-left -4rem
			&--sso
				display flex
				flex-direction column
				align-items flex-start
				.flash
					margin-bottom 4rem
			&__sso-btn-container
				margin-bottom 4rem
				.github-signup-btn
					width 20.8rem
		
	&__section
		margin-bottom 3rem
		mbib()
		&--small
			max-width 20rem
		&__title
			color $secondary40
			margin-bottom 1.2rem
			&__name
				font-weight 600
		&--boxed
			br()
			flexCol()
			.btn
				margin-left auto
			.password-strength
				width 100%
		&__input-and-button
			fullw()
			mbi()
			display flex
			margin-bottom 4rem
	.content
		&__body__container
			padding 0 $contentPadding $contentPadding $contentPadding
		&__sidebar__body
			height 100%
			padding-bottom 2rem
	&__mfa
		mbib(4)
		&--apps
			display flex
			mbib(4)
		&__apps
			display flex
			flex-direction column
			img
				max-width 22rem
			&__badge
				max-height 4rem
		&__add-mobile
			.form
				fullwh()
				flexAxis()
				padding 0 $modalPadding
			&__wrapper
				display flex
				max-width 76%
			&__section
				display flex
				align-items flex-start
				flex-direction column
				min-height 30rem
				width 50%
				padding 0 3rem
				mbib(3)
			&__qr
				margin auto
			&__key
				fullw()
				display flex
				flex-direction column
				align-items center
				justify-content center
				.listify
					display inline-flex
					width auto
	&__device-list
		&.content__body__container
			padding 0
		&__token-list
			margin-bottom 3rem
			&__item
				list-style none
				padding-left 1rem
				margin-left 1rem
				&:before
					font-size .8rem
					position relative
					left -1rem
					top - .2rem
					content "\2022"
					color $secondary-medium60
@media screen and (max-width 1100px)
	.profile
		&__authentication
			flex-direction column
			&__account
				width auto
				min-width unset
				margin 0
				padding 0
				border 0
@media screen and (max-width 720px)
	.profile
		.content
			&__body
				overflow auto
				&__container
					padding 0 1rem
			&__sidebar
				&__body
					padding 0
				.btn
					display inline-flex
				.list
					ul
						:nth-child(3)
						:nth-child(4)
							display none
.add-client-form
	&__modal
		.form
			height 100%
			overflow hidden
		&__body
			height 100%
			overflow hidden
		&__content
			fullwh()
			display flex
			overflow hidden
			padding 0 3rem
			height 100%
			margin-bottom 0 !important
			&__left
			&__right
				mbib(2)
				height 100%
				width 50%
				position relative
				overflow-y auto
			&__left
				padding 3rem 2rem 0 0
			&__right
				padding 3rem 0 0 2rem
				.project__products
					padding 0
					&__list__item
						.card
							flex-direction row
							max-width unset
							width 100%
							border-radius $borderRadius
							overflow hidden
							&__header
								margin-bottom 0
								&__title
									margin-top 1.6rem
							&__img
								sq(6)
								border-radius .5rem 0 0 .5rem
							&__content
								padding 0 2rem
								align-items unset
							&__footer
								padding-top 2rem
								position relative
								.italic
									display block
									margin 3rem 0 1rem 0
								.card
									border 0
									border-radius unset
									min-height unset
									padding 0
									&__img
										sq(3)
										br()
									&__image
										align-items center
										display flex
									&__header
										display flex
										align-items center
										margin-right 2rem
										&__title
											margin-top 0
									&__footer
										display flex
										align-items center
										padding 0


@media screen and (max-width 960px)
	.add-client-form__modal__content
		&__left
			width 40%
		&__right
			width 60%
@media screen and (max-width 720px)
	.add-client-form__modal__content
		display block
		&__left
		&__right
			width 100%
			margin 0
		&__left
			margin-bottom 6rem
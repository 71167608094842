.teams
	.panel
		&__header__content
			.dropdown
				align-self flex-start
				position relative
				top 1rem
				margin 0 2rem 0 1rem
		&__body
			padding-bottom 0
	&__list
		mbib()
		&__item
			width 100%
			&__header
				br()
				trans()
				// background-color $metricsMembers
				border .1rem solid $borderColor
				padding 1rem $padding 1rem $padding + .4
				margin 0 !important
				width 100%
				display flex
				align-items center
				justify-content space-between
				cursor pointer
				&.opened
					border-radius .6rem .6rem 0 0
					&:hover
						box-shadow none
				&:hover
					box-shadow 0 0 .6rem $dark10
				&__body
					ellipsis()
					.title
						fullw()
						ellipsis()
						margin 0 1rem 0 0
						font-size $fontSize
						text-align left
					.paragraph
						margin 0 0 0 auto
						font-size $fontSizeSmall
						white-space nowrap
				&__actions
					display flex
					flex-shrink 0
					align-items center
					&__icon
						width 4rem
						margin 0 0 0 2rem
						.icon
							flex-shrink 0
				.btn--group-container
					margin 0 auto
			&__body
				trans()
				padding 2rem $padding + 1
				width 100%
				background-color $light
				border-radius 0 0 .6rem .6rem
				border .1rem solid $borderColor
				border-top 0
			&--no-members
				.teams__list__item__header
					cursor default
					border .1rem solid $secondary-medium15
					.title
					.paragraph
						opacity .4
					&:hover
						box-shadow none

	&__member-list
		fullw()
		&__item
			ellipsis()
			margin-bottom .4rem
			list-style disc inside
			&::marker
				color $secondary
			.btn
				text-decoration none

@media screen and (max-width 720px)
	.teams
		.list
			&__header
			&__col
				display none
				&.list__col--fill
					display flex
			&__cols
				.list__col:nth-child(2)
					display flex
					margin-left auto
					width auto
		.content__body__header
			.btn
				display none
		.panel
			&__header
				padding 1rem
			&__title
				line-height 1
				margin-bottom .6rem
			&__body
				padding-top 1.6rem
			.navtab
				margin-bottom 1.6rem
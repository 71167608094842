.members
	.for-mobile
		display none
	&__list
		&__email
			max-width 40rem
		&__mfa
			max-width 3rem
			text-align center
	&__invite
		.modal__body__form
			.form
				mbib(0)
		&__flash
			&__desc
				margin-bottom 1rem
			&__list
				margin-left 2rem
				mbib(.6)
				li
					list-style-type disc
		&__row
			mbi(3)
			fullw()
			margin-bottom 1rem !important
			display flex
			align-items flex-start
			.input__label
				margin-bottom 0
			> :nth-child(1)
				flex 3
			> :nth-child(2)
				flex 2
			> :nth-child(3)
				flex 2
			> :nth-child(4)
				align-self flex-start
				margin-top .8rem
			ul
				mbib()
			&__empty
				sq(2.4)
			&--header
				margin-bottom 0 !important
			
			.input--checkbox
				min-height $inputHeight
				align-items center
				display flex
		&__more
			margin-top 3rem
@media screen and (min-width 720px) and (max-width 840px)
	.members
		.content
			&__body
				&__header
					height auto
					padding-top 1.2rem
					padding-bottom 1.6rem
					.btn--link-info
						margin-bottom 2rem
						.icon
							position relative
							top .7rem
							margin-right .2rem
							left auto
					&__title
						.title
							margin-bottom 1.2rem
					&__stats
						flex-wrap wrap
				&__section
					flex-direction column
					&__title
						.select
						.input
							min-width 12rem
@media screen and (min-width 720px) and (max-width 830px)
	.members
		.content
			&__body
				&__header
					flex-wrap wrap
				.btn--link-flex-info
					width 100%
					order 3
					margin-top 1rem

@media screen and (max-width 720px)
	.members
		.content__body__header
			.mbi
				display none
		.list
			&__header
				padding 0 4rem 0 2rem
			&__col
				&:not(.members__list__role)
					display none
				&.list__col--fill
					display flex
		&__list
			&__role
				display flex
				justify-content flex-end
		.for-mobile
			display inline-flex
		.for-desktop
			display none
.assets-list
	padding 0 $contentPadding
	&__list
		&__id
			color $secondary-medium50
			font-weight 700
		
@media screen and (max-width 720px)
	.assets-list
		&__list
			&__id
				min-width 5rem
				width 35%
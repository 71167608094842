$plpad = 1rem
$plpad2 = $plpad * 2
.product-locator
	fullwh()
	padding $plpad
	background-color $dark10
	&__modal
		.locator__aside
			max-width 40rem
	&__wrapper
		fullwh()
		br()
		overflow hidden
		display flex
		border .1rem solid $dark30
	&__product
		box()
		position absolute
		top $plpad * 2
		left $plpad * 2
		width 32rem
		padding 1rem 2rem 2rem 2rem
		background-color $light
		min-height 6rem
		overflow hidden
		animation: unset
		&.product--hidden
			animation hideInfo .4s ease
			animation-fill-mode forwards
		&.product--showed
			animation showInfo .8s ease
			animation-fill-mode forwards
		&__display-btn
			position absolute
			right 1.4rem
			top 1.8rem
		&__title
		&__desc
			position relative
		&__image
			position relative
			margin 2rem auto
			max-width 20rem
		&__price
			mbi()
			display flex
			align-items center
		&__amount
			br()
			padding 1rem 1.4rem
			font-size $title
			line-height 1.2
			font-weight 600
			background-color #2FBC73
			color $light
			white-space nowrap
		&__taxes
			color #999
			font-size 1.2rem
			line-height 1.2
	&__map
		overflow hidden
		width 100%
		height 100%
		background-color rgb(109, 121, 100)
	&__store-selector
		flexCol()
		mbib(1)
		width 38rem
		height 100%
		overflow hidden
		flex-shrink 0
		background-color #fff
		box-shadow 0 0 2rem $dark20
		&__logo
			max-width 10rem
		&__header
			mbib(1)
			padding $plpad2 $plpad2 $plpad $plpad2
			width 100%
.store
	&-selected
		margin 1.4rem 0
	&-list
		mbib(1.4)
		height 100%
		width 100%
		overflow-y auto
		padding 0 $plpad2 $plpad2 + 1 $plpad2
	&-card
		box()
		trans()
		display inline-flex
		padding 1.4rem
		cursor pointer
		width 100%
		&:not(.disabled)
			.store-distance
				color $logo3
		&.selected
		&:hover
			box-shadow 0 0 .3rem $dark10
			border-color $logo3
		&.selected
			border-color $logo3
			cursor default
		.product-locator__product &
			box-shadow none
			margin-bottom 1.4rem
			&:hover
				box-shadow none
				border-color $borderColor
		&.disabled
			color $dark40
	&-image-container
		max-width 3rem
		padding-top .4rem
		padding-left .2rem
		margin-right 1.4rem
		.disabled &
			opacity .4
	&-info
		font-size 1.3rem
		padding .2rem 0
	&-name
		font-size 1.5rem
		margin-bottom .6rem
		line-height 1.1
	&-address
		margin-bottom .6rem
	&-stock
		mbi(1)
		display flex
		align-items center
		font-weight 600
		margin-bottom 1rem
		&-badge
			br(3)
			sq(1.4)
			display flex
		&.available
			color $success-dark
			.store-stock-badge
				background-color $success-dark
		&.not-available
			color $error
			.store-stock-badge
				background-color $error

@keyframes hideInfo
	0%
		max-height 100%
	100%
		max-height 6rem
@keyframes showInfo
	0%
		max-height 6rem
	100%
		max-height 100%
.onboarding
	fullwh()
	flexAxis()
	&__container
		text-align center
		margin-bottom 8rem
	&__header
		margin-bottom 3rem
		.title
			font-size $title + 2
			font-weight 400
		.subtitle
			font-size $title
			color $primary
		&__img
			max-width 12rem
			margin 0 auto 2rem auto
	&__body
		font-size $title4
		margin-bottom 4rem
		p
			margin-bottom .8rem
@media screen and (max-width 720px)
	.onboarding
		padding 0 1.6rem

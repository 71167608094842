zindex()
	position relative
	z-index 10
@keyframes gradient
	0%
		background-position 0% 50%
	50%
		background-position 100% 50%
	100%
		background-position 0% 50%
@keyframes bgimage
	0%
		background-size 100%
		background-position 100% 100%
		opacity .6
	50%
		background-size 20%
		background-position 0% 0%
		opacity 1
	100%
		background-size 100%
		background-position 100% 100%
		opacity .6

.login-responsive-wrapper
	display flex
	align-items center
	justify-content center
	flex-direction column
	height 100%
	width 100%
	overflow-x hidden
	background linear-gradient(45deg, #6313ff, #a655ff, $primary, $primary, $primary, #00e1ff, #00e1ff)
	background-size 400% 400%
	animation gradient 15s ease-in-out infinite
.login
	fullwh()
	display flex
	align-items center
	justify-content center
	flex-direction column
	position relative
	z-index 1
	color $light
	overflow-x auto
	&__loading
		fullwh()
		color $light
		display flex
		align-items center
		justify-content center
	&__gfx
		z-index 0
		position absolute
		top 0
		left 0
		width 100%
		height 100%
		background-size 200%
		background-image url(../../../images/sidebar-white-bg.png)
		animation bgimage 90s ease infinite
	&__logo
		zindex()
		text-align center
		margin 3rem auto 0 auto
		flex-shrink 0
		img
			max-width 17rem
			margin auto
			margin-bottom 1rem
		.title
			zindex()
			font-size $title2
			line-height 1
			font-weight 300
			color $light
			text-shadow 0 0 2rem $dark
			margin-bottom 0
		.btn
			margin-bottom auto
	&__form
		zindex()
		flex-shrink 0
		color $secondary
		width 100%
		max-width 40rem
		margin 3rem auto
		font-size $fontSize - .1
		.form
			br()
			padding 2rem 3rem
			background $light
			box-shadow 0 0 3rem $dark25, inset 0 -8rem 10rem $secondary20
			.input--checkbox
				margin-bottom 0
			.btn:not(.login__form__header__btn-back)
				margin-top .4rem
				margin-bottom 1rem
				width 100%
				justify-content center
			.form__reset-password
				margin-bottom 2rem
				.btn
					width auto
			.login__form__mfa
				margin-bottom 0
				.btn
					width auto
		&__email-sent
			.icon
				stroke $success
			h1.title
				margin-bottom 2rem !important
			.login__form__content
				text-align center
			.input
				width 22rem
				text-align center
				margin auto
				&__label
					font-weight 700
					font-size 2rem
					margin-bottom 2rem
				&__item
					font-size 3rem
					height 6rem !important
		&__header
			margin-bottom 1rem
			position relative
			text-align center
			h1.title
				font-weight 400
				font-size $title
				color $primary
				margin-bottom 0
				line-height 1
				.login__form__email-sent &
					color $success
					font-weight 400
					font-size 2.2rem
					margin-bottom 2rem
				.login__form__mfa &
					font-weight 400
					font-size 2.2rem
					margin-bottom 1.6rem
				.signup-fieldset &
					margin-bottom 2rem
			h2.title
				font-weight 400
				font-size $fontSize
				margin-bottom 0
			p.paragraph
				text-align left
			.signup-fieldset &
				h2.title
					font-size $title5
			&__btn-back
				position absolute
				left -1.1rem
				top -.5rem
		&__body
			mbib()
			.login__form__mfa &
				margin-bottom 2rem
		&__footer
			flexSpread()
			mbib()
			margin 1.4rem auto !important
			flex-basis 100%
			.input
			.link
				flex-grow 1
				white-space nowrap
			.signup-fieldset &
				justify-content initial
				align-items flex-start
				.input--checkbox
					max-width 3rem
		&__mfa
			h5.title
				text-align center
				margin-bottom 2rem
			.flash.flash--error
				height 3.8rem
				margin-bottom 2rem
			.flash
				height 8rem
				margin-bottom 2rem
	.login-fieldset
		.input
			&:not(.input--checkbox)
				.input
					margin-bottom 1.4rem
					&__item
						padding 0 1.6rem 0.2rem 1.6rem !important
					&__label
						margin-bottom .6rem
	.signup-fieldset
		.signup-items
			display flex
			justify-content space-between
			margin-bottom 1.2rem
			&:not(.email-item)
				.input
				.select
					width 48%
		.signup-step
			text-align center
			.input:not(.input--checkbox)
				margin-bottom .6rem
	.signup-tos
		font-weight 600
		display flex
		justify-content center
		margin-bottom 3rem
		.input--checkbox
			max-width 3rem
	.input
		&:not(.input--checkbox)
			.input__item
				font-size $fontSize - .1
	&__sso
		margin 1rem auto 0 auto
		.google-sso-container
		.github-sso-container
			display flex
			justify-content center
		.google-sso-container
			margin-bottom 1rem
		.sso-buttons
			position relative
			margin-bottom 2rem
			.signup-cache
				br(.3)
				width 100%
				height 12.8rem
				z-index 100
				position absolute
				top -1.6rem
				left 0
				padding 1.6rem
				background-color $light70
		.github-signup-btn
			width 20.8rem
			margin 0 auto
			cursor pointer
		&__separator
			display flex
			align-items center
			margin-bottom 2rem
		&__line
			flex-grow 1
			border-bottom .1rem solid $dark10
		&__or
			color $dark60
			width 8rem
			margin auto
			display flex
			justify-content center
			align-items center
		&__google-btn
			margin-top 2rem
	&__footer
		zindex()
		flexAxis()
		flex-shrink 0
		margin 0 auto 3rem auto !important
	&__fake-menu
		display none
		align-items center
		padding 0 3rem
		flex-shrink 0
		width 100%
		height 5rem
		position relative
		z-index 10
		background-color $light20
		.btn
			color $light
			cursor pointer
@media screen and (max-width 767px)
	.login
		&__footer
			display none
		&__fake-menu
			display flex
@media screen and (max-height 700px)
	.login
		align-items flex-start
		justify-content flex-start
@media screen and (max-width 460px)
	.login
		&__form
			padding 0 1rem !important
			max-width 100%
			width 100%
			.form
				padding 3rem 2rem
				.btn
					margin-top 0
			h1.title
				font-size $title
				font-weight 700
			h2.title
				font-size $title3
			.flex.mbi
				flex-direction column
				.input
					margin-right 0
					&:first-child
						margin-bottom 2rem
			&__footer
				flex-direction column
				align-items center
				.input--checkbox .input__line
					justify-content center
					margin-bottom 2rem
		&__logo
			margin-bottom 2rem
			.title
				font-size $title2
				margin-top 1rem
				color $light
		&__footer
			margin-top 3rem
.teams-and-auth
	padding 0 $contentPadding
	display flex
	flex-direction row
	justify-content space-between
	&__team-selection
		margin-bottom 3rem
		.flash
			margin-bottom 3rem
		.select__label
			font-weight 700
	&__permissions
		margin-bottom 4rem
		&__line
			mbi(1)
			display flex
			align-items center
			.input
				width auto
	.select__clear-indicator
		display none
@media screen and (max-width 720px)
	.teams-and-auth
		&.content__body__section
			padding-top 2rem
		&__team-selection
			.select
				display none
.CodeMirror
  font-size 1.6rem

.CodeMirror pre.CodeMirror-line, .CodeMirror pre.CodeMirror-line-like
  font-size 1.6rem
  white-space pre-wrap
  word-break break-all
  word-wrap break-word

.CodeMirror pre
  height 1.6rem

.editor-error
  width 1.6rem
  height 1.6rem
  background-image url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAMAAAAoLQ9TAAAAHlBMVEW7AAC7AACxAAC7AAC7AAAAAAC4AAC5AAD///+7AAAUdclpAAAABnRSTlMXnORSiwCK0ZKSAAAATUlEQVR42mWPOQ7AQAgDuQLx/z8csYRmPRIFIwRGnosRrpamvkKi0FTIiMASR3hhKW+hAN6/tIWhu9PDWiTGNEkTtIOucA5Oyr9ckPgAWm0GPBog6v4AAAAASUVORK5CYII=')
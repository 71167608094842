.venues
	&__modal-add
		&__body
			height 100%
			.dropzone__btn
				height 10rem
			&__routing
				mbib(.5)
				height 100%
				display flex
				flex-direction column
				&__steps
					mbi(.6)
					display flex
					&--untouched
						color $dark40
					&--done
						color $success
						.icon
							stroke $success
					&--warning
						color $warning
						.icon
							stroke $warning
					&--pending
						font-weight 600
				&__warnings
					&__title
						mbi(.4)
						display flex
						color $warning
						margin-top 2.4rem
						.icon
							stroke $warning
				.flash
					padding 1rem 1.4rem
					align-items flex-start
					overflow auto
					max-height 15rem
					margin-top 1.4rem
		&__fieldset
			mbi(3)
			display flex
			margin-bottom 3rem
			.input
				&:first-child
					max-width 18rem
	&__modal
		.modal
			&__header
				.title
					margin-right 3rem
					white-space nowrap
					flex-grow 1
					span
						font-weight 400
						font-size $fontSize
						margin-left 3rem
		&__wrapper
			display flex
			height 100%
			overflow hidden
		&__left
			width 60%
			background $dark4
			overflow hidden
		&__right
			width 40%
		&__header
			&__display-languages
				br(4)
				font-size $fontSizeSmall
				white-space nowrap
				display flex
				align-items center
				height $buttonHeight
				padding 0 .6rem 0 1.6rem
				border .1rem solid $borderColor
				&__labels
					margin 0 3rem 0 1rem
			&__choose-languages
				mbi()
				width 100%
				display flex
				align-items center
				max-width 45.3rem
	&__details
		display flex
		flex-direction column
		height 100%
		border-left .1rem solid $borderColor
		.form
			height 100%
			display flex
			flex-direction column
			margin-bottom 0 !important
		&__map
			width 100%
			height 100%
		&__header
			display flex
			align-items center
			padding 2rem 3rem 0 3rem
			margin-bottom 0 !important
			flex-shrink 0
			&__warning
				mbi(1)
				padding 2rem 3rem 0 3rem
				margin-bottom 0 !important
				color $warning
				.icon
					fill $warning
			&__languages
				display flex
				height 5rem
				border-bottom .1rem solid $borderColor
				background-color $dark3
				overflow-x auto
				flex-shrink 0
				margin-bottom 0 !important
				.btn.btn--group
					border-radius 0
					border-top 0
					border-bottom 0
					height 100%
					min-width 6rem
					justify-content center
				.btn--group-container
					> :first-child
						border-left 0
			.title
				ellipsis()
				margin 0 2rem 0 0
			.input
				margin-right 1.4rem
				width auto
				flex-grow 1
			&__form-actions
				mbi(1)
				margin-left auto
				display flex
				flex-wrap nowrap
		.tab
			height 100%
			overflow hidden
			margin-bottom 0 !important
			&__header
				padding 2rem 3rem 0 3rem
				border-bottom .1rem solid $borderColor
			&__content
				height 100%
				padding 2rem 3rem 0 3rem
				overflow-y auto
				width auto
				margin-top 0
		&__pois-list
			height 100%
			overflow-y auto
			overflow-x hidden
			padding 3rem 1.8rem 0 1.8rem
		.asset-form
			&__custom-fields
				&__fieldset
					max-width unset
					.input
						flex-shrink 1
						min-width unset
						width auto
						flex-grow 1
						input
							width auto
							min-width auto !important

@media screen and (max-width 960px)
	.venues
		&__details
			&__header
				mbi(2)
				.btn--settings
					padding 0 0.6rem !important
					&__label
						display none
					.icon
						margin-right 0 !important
@media screen and (max-width 768px)
	.venues
		&__modal
			&__wrapper
				flex-direction column
			&__left
				height 60%
				flex-shrink 0
			&__left
			&__right
				width 100%
.favorites
	&__orgs
		&__icon
			stroke $favorite
	&__project
		&.favorite
			.icon
				fill $favorite !important
				cursor pointer
		&:hover
			.icon
				stroke $favorite !important

.add-project-success
	.modal__body
		fullwh()
		flexAxis()

	&__container
		text-align center
		.title
			color $success
			font-size $title + 1
			line-height $title + 2
			position relative
			top -3rem

	.flash
		margin-bottom 2rem

	&__img
		max-width 18rem
		margin 0 auto 4rem auto

	&__message
		font-size $title2
		margin-bottom 4rem

	&__actions
		display flex
		align-items center
		justify-content center
		mbi(2)

@media screen and (max-width 720px)
	.add-project-success
	.modal__body
		height 100%

	&__container
		padding 1rem 1rem 6rem 1rem
		display flex
		flex-direction column
		height 100%

	&__img
		max-width 10rem
		margin 0 auto 2rem auto
		flex-shrink 0
	&__container
		.title
			top -2rem
			font-size $title4
			flex-shrink 0

	&__message
		font-size $fontSize
		flex-shrink 0

	&__actions
		flex-shrink 0
		flex-direction column
		mbi(0)
		mbib(1.4)
		padding-bottom 4rem

.project
	height 100%
	display flex
	flex-direction column
	&__nav
		display flex
	&__body
		flex-grow 1
		overflow-y auto
	&__products
		fullwh()
		mbi(2)
		padding 0 $contentPadding
		overflow-y auto
		display flex
		flex-wrap wrap
		.products__section__label
			margin-bottom 1rem
			&__title
				flex-grow 1
				margin-right 0
		&__list
			mbi(2)
			display flex
			flex-wrap wrap
			margin-bottom 4rem
			&__item
				margin-top 1rem
				margin-bottom 1rem
			.card
				box-shadow 0 0 1rem $dark6
				&__footer
					padding-top 0 !important

@media screen and (max-width 720px)
	.project
		&__products
			mbi(0)
			padding 1rem
			> div
				width 100%
			&__list
				width 100%
				mbi(1)
				justify-content center
				&__item
					.card
						margin 0
@media screen and (max-width 520px)
	.project
		&__products
			.card
				max-width unset
			&__list
				mbi(0)
				&__item
					margin 0 0 2rem 0
.activity-list
	margin-bottom 2rem
	.fake-item
		border 0
	.second-title
		padding-top 2rem
	&__col
		&__date
			margin-right 6rem !important
		&__sentence
			&__list-item
				list-style inside
			font-weight 500 !important
			.btn__label
				font-weight 600
			.popover__child
				.content &
					display inline
					line-height 1.5
			&__popover-child
				font-weight 600
				text-shadow none
				border-bottom .2rem dotted $primary
				cursor pointer
				text-decoration none
	&__row
		border 0 !important
	&__type-bullet
		padding-right 1rem
		&--user
			color $lastActivityUser
		&--project
			color $lastActivityProject
		&--team
			color $lastActivityTeam
		&--security
			color $lastActivitySecurity
		&--organization
			color $lastActivityOrganisation

.assets-upload
	&__input-file
		opacity 0
		position absolute
		top 0
		left 0
	.modal__body
		display flex
		align-item center
		min-height 8rem
		mbi()
		.listify
			fullw()
			margin 0
			min-height $inputHeight
			height $inputHeight
			&__item
				span
					word-break unset
					white-space nowrap
					overflow hidden
					text-overflow ellipsis
.link
	text-decoration underline
	color $secondary
	focus()
	&--primary
		color $primary
		text-decoration none
	&--small
		font-size $buttonFontSizeSmall
		line-height $buttonLineHeightSmall
	&--large
		font-size $buttonFontSizeLarge
		line-height $buttonLineHeightLarge
		

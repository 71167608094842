.sidebar
	flexCol()
	align-items center
	position relative
	padding 2.4rem 0 1rem 0
	width $sidebarWidth
	background-color $primary
	color $light
	flex-shrink 0
	.wrapper--admin &
		background-color $client
	&--admin
		background-color $sideBarAdmin
	.icon
		stroke $light
		trans()
	&__header
		padding-bottom 7.8rem
		&__icon
			sq(3)
			opacity .8
	&__main
		flex-grow 1
		&__list
			mbib(.4)
	&__link
		&--push-top
			margin-bottom 2rem
		&--push-bottom
			margin-top 2rem
	&__footer
		ul
			display block
			margin-top 2rem
@media screen and (max-width 720px)
	.sidebar
		height 5rem
		order 2
		flex-direction row
		flex-shrink 0
		width 100%
		padding 1rem 0
		box-shadow 0 1rem 2rem 1rem $dark30
		z-index 1001
		&__header
			display none
		&__main
		&__footer
			display flex
			margin 0
			ul
				margin 0
				display flex
				flex-shrink 0
			&__list
				&__item
					margin-right 0
		&__main
			&__list
				mbib(0)
		&__link
			br(50)
			sq(4)
@media screen and (max-width 400px)
	.sidebar
		&__main
		&__footer
			.btn--sidebar-link
				width 3.8rem
				.icon
					sq(2.2)
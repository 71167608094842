.chargebee-modal
	background-color $dark70
	&__header
		display flex
		align-items center
		justify-content center
		flex-direction column
		min-height 6.4rem
		padding 1.2rem 3.6rem
		box-shadow 0 .1rem .2rem 0 $dark10
		position relative
		z-index 1
		&__logo
			sq(4)
			margin .3rem auto
		.title
			font-weight 600
			font-size $fontSize
			color $dark
			line-height 1
		&__close
			position absolute
			background #393941
			height 2.4rem
			width 2.4rem
			border-radius 50%
			right -1.2rem
			top -1.2rem
			color $light
			text-align center
			.icon
				sq(1.4)
				stroke #fff
				margin-right unset !important
	&__body
		mbib(3)
		display flex
		align-items center
		flex-direction column
		position relative
		z-index 0
		min-height 30rem
		padding 5rem 3.6rem 1.2rem 3.6rem
		background-color $chargebeeModal
		overflow auto
	&__footer
		display flex
		align-items center
		justify-content center
		padding 1.2rem 3.6rem
	.modal
		&__stepper__error
			padding 1rem 3.6rem
			text-align center
			background-color $chargebeeModal
			color $error
		&__content
			width 40rem
			max-width unset
			flex-grow unset
			box-shadow 0 2rem 3rem .1rem $dark15
		&__body
			padding 0
			overflow visible
		&__header
			padding 0
		&__footer
			justify-content center
			.btn
				&--primary
				&--secondary
					border-radius unset
					&:hover
						&:disabled
							background-color $dark
				&--primary
					background-color $dark
				&--transparent
					display none
				
.name-and-domains
	&__section
		fullwh()
		display flex
		align-items center
		justify-content center
		padding $modalPadding $modalPadding 0 $modalPadding
	&__form
		max-width 46rem
		width 100%
		.input
			margin-bottom 4rem
		label
			font-size $title4
			font-weight 700
			margin-bottom 1.6rem
		&__add-domains
			stickedInputButton()
			margin-bottom 2rem
			.input
				position relative
				margin-bottom 0
	&__domains-list
		display flex
		flex-wrap wrap
		.listify
			display inline-flex
			padding 1rem
			width auto
			margin-bottom .4rem
			margin-right .4rem
			min-height 4rem
			background-color $secondary
			color $light
			.icon
				stroke $light
@media screen and (max-width 720px)
	.name-and-domains
		&__form
			height 100%
			label
			h4
				font-size $title5
		&__section
			padding 1rem
			display flex
			flex-direction column
			height 100%
.subscriptions
	.content__body__header
		display flex
		flex-wrap wrap
		&__title-with-action
			width unset
		&__title-container
			display flex
	.content__body__container
		padding 0 0 0 2.8rem
		display flex
		flex-direction row
		flex-grow 1
		overflow hidden
	.title
		margin-bottom 4rem
	.main-title
		font-size $title + 2
		line-height $title * 2
	&__sidebar
		width 30rem
		flex-shrink 0
		padding 2rem $contentPadding 0 0
		height 100%
		border-right .1rem solid $borderColor
		overflow-y auto
		&__section
			display flex
			align-items center
			justify-content space-between
			border-bottom .1rem solid $dark10
			padding 1.6rem 0
			margin-bottom 4rem
			> :first-child
				color $secondary60
		&__section-menu
			display flex
			flex-direction column
			> :first-child
				border-bottom .1rem solid $dark10
				padding 1.6rem 0
				color $secondary60
				margin-bottom 3rem
			li
				list-style inside none
				padding-left 1rem
				margin-bottom 1.3rem
	&__content
		flex-grow 1
		padding 2rem 0 0 $contentPadding
		overflow-y auto
		&.hyperline
			padding 0
			width 100%
			height 100%
			background-color #fafbfc
			overflow hidden
			iframe
				width 100%
				height 100%
				overflow-x hidden !important
		&__header
			mbi(0.8)
			white-space nowrap
			display flex
			align-items center
			margin-bottom 4rem
			margin-top 1rem
			.main-title
				margin-left 2rem
				margin-bottom 0
	&__freemium
		mbi()
		width 100%
		display flex
		align-items center
		.subscriptions__content &
			margin-bottom 4rem
		&__indicator
			br()
			width 4rem
			height 4.6rem
			textShadow()
			display flex
			align-items center
			justify-content center
			flex-direction column
			background-color $success2
			color $light
			padding .6rem
			text-transform uppercase
			:first-child
				font-weight bold
				padding .2rem
				border-bottom .2rem solid $light
			:last-child
				font-size $fontSizeMini
		&__legend
			display flex
			flex-direction column
	&__free-consumption-status
		br()
		display inline-flex
		align-items center
		padding .4rem 1rem
		&--green
			.icon
				stroke $success2
		&--orange
			color $warning
			.icon
				stroke $warning
		&--red
			color $special2
			.icon
				stroke $special2
		&--fill
			color $light
			background-color $special2
			.icon
				stroke $light

.hyperline_subscription_wrapper
	position relative
	height 100%
.hyperline_subscription_overlay
	position absolute
	width 100%
	height 100%
	background-color $light70
	display flex
	justify-content center
	align-items center

@media screen and (max-width 1190px)
	.subscriptions
		.content__body__container
			flex-direction column
		&__sidebar
			width 100%
			height unset
			border unset
			padding-right 0
		&__content
			padding-left 0
.select-project-products
	&__section
		padding $modalPadding $modalPadding 0 $modalPadding
	.content__sidebar
		background-color $light
		.list
			padding-top 1rem
	&__counter
		color $success2
		font-weight bold
		flex-shrink 0
	&__header
		display flex
		align-items center
		fullw()
		mbi(3)
@media screen and (max-width 720px)
	.select-project-products
		&__header
			flex-direction column
			align-items flex-start
			.title
				margin-right 0
				margin-bottom .4rem
				font-size $title4
				line-height 1

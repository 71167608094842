.plans
	fullwh()
	display flex
	align-items center
	justify-content center
	flex-grow 1
	&__wrapper
		display flex
		justify-content flex-start
		align-items center
		flex-direction column
		height 100%
		.title
			margin-top 4rem
	&__container
		width 100%
		display flex
		justify-content center
		flex-wrap wrap
		.plan
			&:nth-child(1)
				.plan__gfx
					background-color $logo8
			&:nth-child(2)
				.plan__gfx
					rainbowInverse()
			&:nth-child(3)
				.plan__gfx
					background-color $logo3
	.plan
		br()
		display flex
		flex-direction column
		align-items flex-start
		padding 4rem 2rem 2rem 2rem
		border .1rem solid $borderColor
		flex-grow 1
		max-width 32rem
		min-width 26rem
		margin 0 2rem 4rem 2rem
		position relative
		&__gfx
			position absolute
			top 0
			left 0
			height 1rem
			width 100%
			border-radius $borderRadius - .1 $borderRadius - .1 0 0
		&__addition-info
			font-size $fontSizeSmall
		sup
			font-size $fontSizeMini
		&__name
			f3()
		&__intro
			min-height 3.6rem
		&__cta
			margin .6rem 0 2.6rem 0
		&__desc
			font-size $fontSizeSmall
		&__main-feature
			font-size $title2
			font-weight bold
			margin-bottom 2rem
			&__item
				margin-bottom .6rem
				sup
					vertical-align text-top
				&--small
					font-weight 400
					font-size $title4

		&__features
			mbib(1)
			margin-bottom 3rem
			sup
				vertical-align text-top
			&__item
				mbi(1)
				display flex
				.icon
					margin-top .2rem
					stroke $success2
		&__link
			position relative
			margin-top auto


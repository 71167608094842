.use-cases
	fullwh()
	display flex
	flex-direction column
	&__header
		position relative
		flex-shrink 0
		padding $contentPadding * 2 $contentPadding 0 $contentPadding
		.title
			font-size $title + 2
			font-weight 400
			color $primary
			text-align center
			margin-bottom 2rem
		.subtitle
			font-size $fontSize + .2
			line-height 1.4
			text-align center
	&__nav
		font-size $fontSize + .2
		.products &
			padding-top 2rem
			margin-bottom 0
		ul
			display flex
			flex-wrap wrap
			justify-content center
			margin-bottom 2rem
			.products &
				margin-bottom 0
		&__link
			br()
			display flex
			align-items center
			justify-content center
			padding 0 1.2rem
			min-height 4rem
			text-decoration none
			line-height 1
			background-color $dark3
			margin .4rem
			&.active
				background-color $primary10
				font-weight 600
	&__body
		fullwh()
		padding 3rem $contentPadding 0 $contentPadding
		overflow-y auto
		scroll-behavior smooth
		.use-cases--padded &
			padding-top 3rem
	&__footer
		display flex
		align-items center
		justify-content center
		padding $contentPadding
		flex-shrink 0

@media screen and (max-width 720px)
	.use-cases
		overflow-y auto
		&__nav
			.products &
				padding 1rem
			&__link
				min-height 2rem
				padding .4rem 1rem
				font-size $fontSize
				margin .6rem
		&__body
			overflow revert
			padding 1rem !important
.support
	.content__body__container
		padding $contentPadding $contentPadding 0 $contentPadding
	&__content
		.title
			text-align center
			margin 0 0 4rem 0
		&__section-container
			mbi(2)
			display flex
			justify-content center
			margin-bottom 6rem
		&__section
			fullw()
			max-width 51.2rem
		&__link
			br()
			mbib()
			trans()
			display flex
			flex-direction column
			background-color $light
			text-decoration none
			padding 1.6rem
			border .1rem solid $borderColor
			box-shadow 0 0 0 $dark10
			&:hover
				box-shadow 0 0 2rem $dark10
		&__header
			mbi(2)
			display flex
			align-items center
			padding-bottom 1rem
			margin-bottom 2rem
			.title
				margin-bottom 0
		&__body
			img
				border .1rem solid $borderColor
				br(1)
	&__footer
		> .title
			text-align center
			margin 0 0 4rem 0
		.support__content
			with 100%
			&__link
				padding-right 3rem
				margin auto
			&__header
				padding 0
				margin 0
			&__section
				display inline-flex
				width 100%
				max-width 100%
@media screen and (max-width 720px)
	.support
		&__content
			&__section-container
				mbi(0)
				mbib(4)
				flex-direction column
				align-items center
		&__footer
			margin-bottom 6rem
.progress
	width 100%
	font-size 1.2rem
	font-weight 600
	> :not(:last-child)
		margin-bottom 1rem
	&__legend
		font-size 1.1rem
		font-weight 400
		text-align center
	&__wrapper
		display flex
		align-items center
		justify-content space-between
		> :not(:last-child)
			margin-right 1rem
	&__bar
		width 100%
		margin auto
		height 0.3rem
		background-color lightgrey
		border-radius .6rem
		overflow hidden
	&__gauge
		height 100%
		.progress__bar--green &
			background-color $progressGreen
		.progress__bar--orange &
			background-color #ff8c00
		.progress__bar--red &
			background-color $progressRed
.navtab
	flexMiddle()
	mbi(2)
	flex-shrink 0
	position relative
	&_tool
		display flex
	li
		min-width 0
	.icon
	.for-mobile
		display none
	&--pills
		mbi(.1)
		display flex
		flex-wrap wrap
		padding 1rem 2.6rem 0 2.6rem
		border-top .1rem solid $borderColor
		border-bottom .1rem solid $borderColor
		background-color $dark3
		.icon
			margin .3rem .2rem
			flex-wrap wrap
		.btn--tab--pills
			height $buttonHeightSmall - .4
			margin-bottom 1rem
			&.active
				background-color $secondary
				color $light
				font-weight 500
				&:hover
					background-color $secondary
					color $light
			&:hover
				background-color $secondary
				color $light

	&--tools
		mbi(.3)
		flexMiddle()
		margin .7rem .2rem .7rem auto
		&:last-child
			margin-left 2rem
		.btn
			display flex
			flex-shrink 0
			.icon
				display block

@media screen and (max-width 960px)
	.navtab
		&--pills
			position unset
		&--tools
			br()
			flex-grow 1
			width calc(100% - 2rem)
			justify-content center
			padding .6rem
			flex-shrink 0
			margin 0
			position absolute
			top 1rem
			left 1rem
			.label
				display flex
				justify-content center
				flex-grow 1
				.btn
					margin-left auto
@media screen and (max-width 880px)
	.navtab
		mbi(1.6)
		.icon
		.for-mobile
			display block
		.for-desktop
			display none
		&--pills
			mbi(1)
			padding 1rem 1.6rem 0 1.6rem
			.btn
				font-size $fontSize - .1
		.btn--tab
			flex-direction column
			align-items center
			height 6.4rem
			max-width 6rem
			min-width 5rem
			flex-shrink 1
			&__label
				font-size $fontSizeSmall
				text-align center
				overflow visible
				white-space normal
				line-height 1
				margin-top .3rem
@media screen and (max-width 350px)
	.navtab
		mbi(.4)
		.btn--tab
			&__label
				font-size $fontSizeMini - .1
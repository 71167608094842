.admin
	.list__col
		.licence-usage
			display flex
			flex-direction column
			gap .4rem
			.progress
				margin-top 0
				width 50%
				margin auto
	&__activity-header
		display flex
		padding 1.4rem 1rem 1rem 3rem
		border-bottom 0.1rem solid $borderColor
		&__title
			margin-left 3rem
			padding-top 0.4rem
	&__manage-product
		.project__products
			padding 0
			&__list__item
				border-bottom .1rem solid $borderColor
				margin 0 0 1.4rem 0
				padding-bottom 1.4rem
				.project__products__list__item
					border 0
		.card
			&.card--small
				border 0
				display flex
				align-items center
				width 100%
				.card
					&__wrapper
						flex-grow 1
					&__img
						br()
						sq(3)
						margin 0 2rem 0 0
					&__footer
						padding-top 0
					&__header
						margin 0 !important
					&__content
						margin 0
						display flex
						flex-direction row
					&__wrapper
						width 100%
						align-items center
					&__aside--addon
						border 0
						padding 0
						width 100%
						margin-left 10rem
						margin-top 2rem
						.card__aside
							&__title
								margin-bottom .6rem
							&__list__item
								&__section
									mbi(1.4)
									display flex
									align-item center
									&__title
										font-size $fontSizeSmall
										margin-bottom 0
								&__image
									sq(2.4)
		.subtitle
			margin 2rem 0 2rem 0
	&__organization-list
		.list
			&__row
				&.row-inactive
					background-color #fff
					background-image url('../../images/texture.png')
			&__col
				&.admin__org-list__name
					.badge
						&--partner
							display flex
							align-items center
							justify-content center
							background-color $primary
							color $light
							font-size .8rem
	&__user-list
		&__email
			.row-inactive &
				width 100%
			&.list__col
				max-width 40rem
				min-width 24rem
				
		&__privileges
			&.list__col
				text-align center
				max-width 9rem
				width 100%
		&__mfa
			&.list__col
				max-width 3rem
				text-align center
	&__projects-list
		&__public-key
			&.list__col
				max-width 50rem
			&__span
				br()
				border .1rem solid transparent
				padding 0 .5rem
				&:hover
					color $dark
					background-color $dark5
					border .1rem solid $dark10
		&__queries
			&.list__col
				max-width 11rem
		&__restrictions
			&.list__col
				max-width 7rem
		&__created-on
			&.list__col
				max-width 9rem
		&__queries
		&__restrictions
		&__created-on
			&.list__col
				text-align center
				
	&__org-list
		&__utm
			.popover__child
				max-width 3.7rem
			&__button
				br(2)
				background-color $light
				border .1rem solid  $adminUtm
				color $adminUtm
				text-shadow none
				height 1.8rem
				line-height 1.1rem
				font-size 1rem
				letter-spacing .1rem
				text-transform uppercase
				padding 0 .7rem
				&:hover
					color $dark !important
					border-color $dark
					background-color transparent !important
			&__table
				&.table
					max-width 29rem
					margin-bottom 0
					tbody 
						tr
							&:nth-child(even)
								background-color rgba(227, 206, 152, .12)

					td
					th
						padding .6rem 1rem
						font-size 1.3rem
						&:last-child
							text-align left

@media screen and (max-width 960px)
	.admin
		&__user-list
			&__name
				&.list__col
					min-width 18rem
			&__email
				&.list__col
					min-width 10rem
		&__projects-list
			&__public-key
				&.list__col
					max-width 50rem
			&__queries
				&.list__col
					max-width 6rem
			&__restrictions
				&.list__col
					max-width 6rem
			&__name
				&.list__col
					min-width 17rem
@media screen and (max-width 720px)
	.admin
		.list
			&__col
				display none
				&.list__col--fill
					display flex
					&.activity-list__col__sentence
						display inline
			&__filter
				.btn--group-container
					display none
			&__header
				padding 0 4rem 0 2rem
			&__row
				&.row-inactive
					.list__col:not(.admin__user-list__name):not(.admin__user-list__email)
						display none
		&__projects-list
			&__restrictions
			&__public-key
				&.list__col
					display inline
			&__name
				&.list__col
					max-width 18rem
		&__user-list
			&__organization
				&.list__col
					display flex
					justify-content flex-end
					margin-left auto
					max-width 30rem
					width 100%
					span
						ellipsis()
			&__email
				.row-inactive &
					display flex
					justify-content flex-end
					margin-left auto
					max-width 30rem
					width 100%
					margin-right 0 !important
					min-width unset
					span
						ellipsis()
				.inactive-label
					flex-shrink 0
			&__name
				&.list__col
					min-width 18rem
					max-width 40rem
					width 100%
				.row-inactive &
					min-width 22rem
					display block
			&__privileges
				&.list__col
					display block
					text-align center
				.list__header &
					visibility hidden

		&__org-list
			&__status
				&.list__col
					display flex
					max-width 7rem
			&__actions
			&__queries
				&.list__col
					display flex
					justify-content center
					span
						ellipsis()
			&__queries
				&.list__col
					max-width 6rem
					text-align right
			&__name
				&.list__col--fill
					width 100%
					min-width unset
					max-width unset
				
@media screen and (max-width 460px)
	.admin
		.list
			&__heade
				display none
		&__user-list
			&__privileges
				&.list__col
					display none
		&__org-list
			&__queries
				&.list__col
					display none
@media screen and (max-width 300px)
	.admin
		&__user-list
			&__organization
			&__email
				&.list__col
					display none
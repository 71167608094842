.projects
	&--overview
		.content
			&__body
				overflow-y auto
				display block
				&__header
					position relative
					z-index 1000
					&__title-with-action
						width 100%
						display flex
						justify-content space-between
					.title
						margin-right auto
					.select
						max-width 22rem
	&__overview
		position relative
		&.content__body__container
			padding-top 0
			overflow unset // for stickyness
		&__products
			padding $contentPadding $contentPadding 0 $contentPadding
		.project-metrics
			&__legend
				margin 4rem 0
				flex-shrink 0
				max-width 100%
				padding 0
				li
					flex-shrink 0
					padding-right 0
					&:nth-child(odd)
						padding-right 0
					&:nth-child(even)
						padding-left 0
				&__item
					br()
					border .1rem solid $borderColor
					flex-shrink 0
					padding 1rem
					margin-bottom .6rem
					background-color $light
					&:hover
						background-color $primary10
				&__label
					&__name
						margin-right 4rem
						width 100%
						max-width 70%
					&__queries
						width 100%
						ellipsis()
						&-container
							width 100%
							max-width 25%
							margin-right 2rem
							ellipsis()
			&__graphs-line
				canvas
					position sticky
					top 0rem
					background-color $light
					z-index 999
					margin 3rem 0
					max-height $canvasMaxHeight
	.list
		padding-top 2rem
		&__header
			padding-top 1rem
			padding-bottom 2rem
			border-bottom .1rem solid $borderColor
			background-color transparent
			background-color $dark3
@media screen and (max-width 970px)
	.projects
		&__overview
			&__stats
				mbi(1)
				padding .8rem 1.2rem
				font-size $fontSizeMini
				letter-spacing 0
				&__value
					font-size $title4
@media screen and (max-width 840px)
	.projects
		.content
			&__body
				&__section-with-action
					&.content__body__section
						align-items center
						justify-content flex-start
				&__menu
				&__header
					padding 0 1.6rem
				&__header
					flex-direction column
					justify-content flex-start
					align-items flex-start
					height auto
					padding 1rem 1.6rem
					.btn--link-info
						margin-bottom 2rem
						.icon
							position relative
							top .7rem
							margin-right .2rem
							left auto
					&__stats
						margin-top 1.6rem
					&__title
						.title
							margin-bottom 1.2rem
						&-with-action
							.title
								margin-bottom 0
					&__stats
						flex-wrap wrap
				&__section
					flex-direction column
					&__title
						order 1
						width 100%
						margin 1.6rem 0
						.select
						.input
							min-width 12rem
					&__filters
						margin-top 1rem
						margin-right 0
					&__actions
						margin-right auto
						margin-left 0
						.btn--multi-options
							margin-left auto
@media screen and (max-width 720px)
	.projects
		.list
			&__header
				padding-left 1.4rem
				padding-right 1.4rem
			&__col
				&:first-child
					max-width 6rem
		&__overview
			&__stats
				flex-direction column
				flex-grow 1
				mbi(0)
			.project-metrics
				&__graphs-line
					canvas
						padding 0
						margin-bottom 1.6rem
				&__legend
					margin 0
					&__item
						position relative
					&__label
						flex-direction column
						&__name
							max-width 80%
							margin 0 0 .6rem 0
							order 2
						&__queries-container
							order 3
							max-width 80%
							margin 0
		&--overview
			.content__body__header
				.select
					max-width 16rem
		.content__body__header
			padding 1rem